import { useHistory } from "react-router-dom";
import { useDisconnect } from 'wagmi';

export function useLogout() {
  const { disconnect } = useDisconnect();
  const history = useHistory();

  const logout = (redirect=true, refresh=true) => {
    localStorage.clear();
    disconnect();
    if (redirect) {
      history.push("/");
    }
    if (refresh) {
      window.location.reload();
    }
  }

  return logout;
}
