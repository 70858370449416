import React from "react";
import PropTypes from "prop-types";

import ImageButton from "./ImageButton";

const Image = ({ offer, onOfferChange, onImageChange }) => {

  const handleFileUpload = (event) => {
    if (!event.target.files) {
        return;
      }
      const file = event.target.files[0];

      let newOffer = {...offer};
      newOffer['file'] = file;
      newOffer['filename'] = file && file.name;
      onOfferChange(newOffer);
      onImageChange(event.target.files[0]);
  };

  return (
    <div>
      <h3 className={`new-form-section`}>Image</h3>
      <ImageButton
        filename={offer.filename}
        onChange={(event) => handleFileUpload(event)}
      />
    </div>
  );
}

Image.propTypes = {
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  onImageChange: PropTypes.func,
};

export default Image;
