import React from 'react'

const LoadingButton = () => {
  return (
    <button
        disabled={true}
        className="payment-button"
        >
        <span id="button-text">
            <div className="payment-spinner" id="spinner"></div>
        </span>
    </button>
  )
}

export default LoadingButton