import { shortWallet } from 'utils/shortWallet';

export const studentsColumns = [
    {
      field: 'fullName',
      name: 'Full name',
      sortable: true,
      width: 200,
      selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
    },
    { 
      field: 'email',
      name: 'Email Address',
      sortable: true,
      width: 210,
      selector : (row) => row.email
    },
    { 
      field: 'wallet_address', 
      name: 'Wallet Address',
      sortable: true,
      width: 200,
      selector: (row) => shortWallet(row.wallet_address)
    },
    { 
      field: 'rewards_count',
      name: 'Rewards received',
      sortable: false,
      width: 200,
      selector: (row) => row.rewards_count
    }
  ];
  