import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const ImageButton = ({ filename, onChange }) => {
  return (
    <div className={`new-form-field`}>
      <Button
        fullWidth
        component="label"
        variant="outlined"
        color="warning"
        startIcon={<UploadFileIcon />}
      >
        {filename ? `Uploaded ${filename}` : `Upload image *`}
        <input type="file" accept="image/*" hidden onChange={onChange} />
      </Button>
    </div>
  );
};

ImageButton.propTypes = {
  filename: PropTypes.string,
  onChange: PropTypes.func,
};

export default ImageButton;
