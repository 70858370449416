import React from 'react'
import PropTypes from 'prop-types'

import { courseType } from 'utils/constants';

import { Autocomplete, TextField } from '@mui/material'

const CrowdclassPanel = ({offer,
                          onOfferChange, 
                          isDisabled,
                          organizationNfts,
                          filteredOrganizationNfts,
                          onFilteredOrganizationNftsChange,
                          organizationCollections,
                          onMysteryBoxCollectionsChange,
                          nftInputValue, 
                          onNftInputValueChange,
                          collectionInputValue,
                          onCollectionInputValueChange,
                         }) => {
                          
  const handleCourseChange = (newValue) => {
    if (newValue) {

      const filteredOrganizationNfts = organizationNfts.filter(
        item => item.course_id === newValue.id
      )
      onFilteredOrganizationNftsChange(filteredOrganizationNfts)

      const filteredMysteryBoxCollections = organizationCollections.filter(
        item => item.id !== newValue.id && item.category === courseType.MYSTERYBOX.value && item.published
      )
      onMysteryBoxCollectionsChange(filteredMysteryBoxCollections)

      const updatedContentCollections = offer.content_collections.filter(item => item !== newValue.id)
      onOfferChange({
        ...offer,
        course_id: newValue.id,
        course_title: newValue.label,
        content_collections: updatedContentCollections,
      })

    }
  };
  
  const handleRewardChange = (newValue) => {
    onOfferChange({
      ...offer,
      reward_id: newValue ? newValue.id : null,
      reward_title: newValue ? newValue.label : null,
    })
  };

  const rewardIsDisabled = filteredOrganizationNfts.length < 1 && !offer.reward_id || isDisabled;

  return (
    <div className='internal_offer_selector-container'>
      <Autocomplete
        fullWidth
        name="course_id"
        value={offer && offer.course_title}
        onChange={(event, newValue) => handleCourseChange(newValue)}
        inputValue={collectionInputValue}
        onInputChange={(event, newInputValue) => onCollectionInputValueChange(newInputValue)}
        options={organizationCollections}
        disabled={isDisabled}
        disableClearable
        renderInput={(params) => 
                      <TextField
                        {...params}
                        name="course_id"
                        color="warning"
                        label="Collection *"
                      />
                    }
      />
      
      <Autocomplete
        fullWidth
        name="reward_id"
        value={ offer && offer.reward_title }
        onChange={(event, newValue) => handleRewardChange(newValue)}
        inputValue={ nftInputValue }
        onInputChange={(event, newInputValue) => onNftInputValueChange(newInputValue)}
        options={ filteredOrganizationNfts }
        disabled={ rewardIsDisabled }
        renderInput={(params) => 
                      <TextField 
                        {...params}
                        name="reward_id"
                        color="warning"
                        label="Associated NFT" 
                      />
                    }
        />
    </div>

  )
}

CrowdclassPanel.propTypes = {
    offer: PropTypes.object,
    onOfferChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    organizationNfts: PropTypes.array,
    filteredOrganizationNfts: PropTypes.array,
    onFilteredOrganizationNftsChange: PropTypes.func,
    organizationCollections: PropTypes.array,
    onMysteryBoxCollectionsChange: PropTypes.func,
    nftInputValue: PropTypes.string,
    onNftInputValueChange: PropTypes.func,
    collectionInputValue: PropTypes.string,
    onCollectionInputValueChange: PropTypes.func,
}

export default CrowdclassPanel;