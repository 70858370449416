import formattedDate from "utils/formattedDate";

export const offerInstancesColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "30%",
    editable: true,
  },
  {
    field: "userId",
    name: "User Id",
    selector: (row) => row.user_id,
    sortable: true,
    width: "10%",
    editable: true,
  },
  {
    field: "nft",
    name: "NFT",
    selector: (row) => row.reward_instance_title,
    sortable: true,
    width: "20%",
    editable: true,
  },
  {
    field: "redeemState",
    name: "Status",
    selector: (row) => row.redeem_state,
    sortable: true,
    width: "15%",
    editable: true,
  },
  {
    field: "createdAt",
    name: "Created at",
    selector: (row) => formattedDate(row.created_at),
    sortable: true,
    width: "15%",
    editable: true,
  },
];
