import React from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";

import PublicLayout from 'components/layouts/Public';
import LogInForm from 'components/authForms/LogInForm';

import { termsOfUseLink, privacyPolicyLink } from 'utils/urls';
import UserAccountResource from 'resources/user/UserAccountResource';
import redirectToView from 'utils/redirectToView';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import LastCourse from 'utils/localStorage/LastCourse';

import Link from '@mui/material/Link';

import { t } from 'i18n/index';

const Login = () => {
  const { fetch } = useController();
  const history = useHistory();

  const authToken = AuthToken.read();

  const coursesList = CoursesList.read();

  const lastCoursesList = LastCourse.read();
  const lastOrganizationId = lastCoursesList && Object.keys(lastCoursesList[0])[0]
  const lastCourseId = lastCoursesList && lastCoursesList.find(element => element[lastOrganizationId])[lastOrganizationId];

  if(authToken) {
    fetch(UserAccountResource.detail(), {id: 0})
    .then(({ role }) => {
      redirectToView(history, role, coursesList, lastOrganizationId, lastCourseId);
    })
    .catch(() => {
      // do nothing
    });
  }

  return (
    <PublicLayout isLogin={true} >
        <div className="form-title hidden">{t('loginPage:loginPage_Title')}</div>
        <p>{t('loginPage:login_EnterMailText')}</p>

        <LogInForm buttonText={t('loginPage:loginPage_LogInButton')}/>

        <div className='footer-labels'>
          <p className="label-terms" >
            <Link href={termsOfUseLink}
                  target="_blank"
                  underline="none"
            >
               {t('tcLabel:fifthPhrase')}
            </Link>
          </p>
          <p className="label-privacy">
            <Link href={privacyPolicyLink}
                  target="_blank"
                  underline="none"
            >
              {t('tcLabel:sixthPhrase')}
            </Link>
          </p>
          <p className="label-crowdclass">Crowdclass (c) {new Date().getFullYear()}</p>
        </div>
    </PublicLayout>
  );
};

export default Login;
