import React from 'react';
import PropTypes from 'prop-types';

import { t } from 'i18n/index';
import { Link } from '@mui/material';
import { useLogout } from 'utils/useLogout';

const FeedBackUser = ({onHelpText, errorMessage, isLoggedIn, name }) => {
    if (!onHelpText && !errorMessage && !isLoggedIn) return null;

    const logout = useLogout();
    const redirect = false;

    return (
        <div className='user-notice'>
            {onHelpText && !errorMessage ?
                <p>
                    { onHelpText }&nbsp;
                    { isLoggedIn && <Link underline="hover" color="inherit" href={'/profile'}>{t('publicClaimPage:profile')}</Link>}
                </p>
            : errorMessage &&
                <p>
                    { errorMessage }&nbsp;
                    { isLoggedIn && <Link underline="hover" color="inherit" href={'/profile'}>{t('publicClaimPage:profile')}</Link>}
                </p>
            }
            {isLoggedIn &&
                <p>
                    {t('publicClaimPage:currentlyLoggedIn')}&nbsp;
                    <b>{ name }</b>.&nbsp;
                    <Link underline="hover" color="inherit" onClick={ () => logout(redirect) }>{t('publicClaimPage:logOut')}</Link>
                </p>
            }
        </div>
    );
};

FeedBackUser.propTypes = {
    onHelpText: PropTypes.string,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool,
}

export default FeedBackUser;
