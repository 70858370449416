export const customStyles =  {
    rows: {
        style: {
            borderBottomColor: '#FDECEF !important',
            maxHeight: '52px',
            minHeight: '56px',
            lineHeight: '56px', 
            fontFamily: "Syne",
            fontWeight: 400,
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    headRow: {
        style: {
            borderBottomColor: '#FDECEF',
        }
    },
    headCells: {
        style: {
            fontFamily: "Syne",
            fontWeight: 400,
            fontSize: 16,
            color: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: 'white',
        },
    },
    cells: {
        style: {
            backgroundColor: 'white',
        }
    }
  }