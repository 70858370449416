import React from "react";
import PropTypes from "prop-types";

import MUIAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const Accordion = ({ expanded,
                     onChange,
                     disabled,
                     title,
                     details,
                  }) => {
  return (
    <MUIAccordion
      expanded={ expanded }
      onChange={ onChange }
      disabled={ disabled }
    >
      <AccordionSummary>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </MUIAccordion>
  );
};

Accordion.propTypes = {
    expanded: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    details: PropTypes.func, 
};

export default Accordion;
