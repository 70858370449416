import ApiResource from "../ApiResource";

export default class PaymentProviderResource extends ApiResource {
    static urlRoot = '/payment_provider' as const;

    readonly id: number | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}