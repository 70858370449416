import React from 'react';
import PropTypes from 'prop-types';

import TabPanels from 'components/TabPanels';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { organizationColumns } from 'utils/columns/admin/organizationsColumns';

const DataTable = ({ records, handleActiveOrg, handleOpenEditModal }) => {

  return (
    <TabPanels labels={[ "Organizations" ]} >
        <DataSelectionTable records={records}
                        columns={organizationColumns(handleActiveOrg, handleOpenEditModal)}
                        checkboxSelectRows={false}
        />
    </TabPanels>
  );
}

DataTable.propTypes = {
  records: PropTypes.object,
  handleActiveOrg: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

export default DataTable;