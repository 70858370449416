import React from "react";

import { Link } from "react-router-dom";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { IconButton } from "@mui/material";

export const coursesColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "17.5%",
  },
  {
    field: "category",
    name: "Category",
    selector: (row) => row.category,
    sortable: true,
    width: "17.5%",
  },
  {
    field: "organization",
    name: "Org.",
    selector: (row) => row.organization.title,
    sortable: true,
    width: "15%",
  },
  {
    field: "organizationId",
    name: "Org. Id",
    selector: (row) => row.organization.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "invite",
    name: "Invite",
    selector: (row) => row.invite,
    sortable: true,
    width: "20%",
  },
  {
    field: "select",
    selector: (row) => (
      <IconButton
        component={Link}
        to={`/admin/collections/${row.id}`}
        variant="contained"
        color="warning"
      >
        <ZoomInIcon />
      </IconButton>
    ),
    sortable: true,
    width: "10%",
  },
];
