import React from "react";
import PropTypes from "prop-types";

import { revealSupplyLimitCheck } from 'utils/revealRedeemLimit';
import ImageButton from "../image/ImageButton";

import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

const RevealPanel = ({ offer,
                       onOfferChange,
                       isDisabled,
                       mysteryBoxCollections,
                    }) => {

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const contentCollections = offer.content_collections;
    const updatedSelectedOptions = checked ?
                                      [...contentCollections, +name]
                                    : 
                                      contentCollections.filter((item) => item !== parseInt(name))

    onOfferChange({ ...offer, content_collections: updatedSelectedOptions});
  };
  
  const differentClaimLimits = !revealSupplyLimitCheck(mysteryBoxCollections, offer.content_collections);

  const handleFileUpload = (event) => {
    if (!event.target.files) {
        return;
      }
      const file = event.target.files[0];

      let newOffer = {...offer};
      newOffer['reveal_file'] = file;
      newOffer['reveal_filename'] = file && file.name;
      onOfferChange(newOffer);
  };

  return (
    <div>
      {mysteryBoxCollections.length > 0 ?
        <div>
          {mysteryBoxCollections.map((collection) => (
            <div key={collection.id}>
              <FormControlLabel
                error
                control={
                  <Checkbox
                    disabled={isDisabled}
                    checked={offer.content_collections ? offer.content_collections.includes(collection.id) : false}
                    name={collection.id}
                    onChange={handleCheckboxChange}
                  />
                }
                label={collection.label}
              />
              
            </div>
          ))}
          {!isDisabled && 
          <ImageButton
            filename={offer.reveal_filename}
            onChange={(event) => handleFileUpload(event)}
          />}        
        </div>
      :
        <FormHelperText error>
          There are no collections suitable to be used as associated collection.
        </FormHelperText>
      }

      {differentClaimLimits && 
        <FormHelperText error>
          The selected collections have different supply limits. When redeeming the offer, NFTs from the associated collections will be issued to the first collectors of NFTs from the main collection, limited by the supply of each associated collection.
        </FormHelperText>
      }
    </div>
  );
};

RevealPanel.propTypes = {
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  mysteryBoxCollections: PropTypes.array,
};

export default RevealPanel;
