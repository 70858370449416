import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import MerchDetailsResource from 'resources/user/MerchDetailsResource';

import CountrySelector from 'components/CountrySelector';
import PreferencesForm from './PreferencesForm';
import DeliveryDetailsForm from './DeliveryDetailsForm';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import { Button, FormControl } from '@mui/material';

import { t } from 'i18n/index';

const MerchDetailsForm = ({ onError }) => {

    const DetailsData = useSuspense(MerchDetailsResource.detail(), {id: 1});

    const [details, setDetails] = useState({...DetailsData});
    
    const { fetch } = useController();
    const { setOpen, setMessage, setSeverity } = useToast();

    const {shirt_size, address, city, post_code, country} = details;

    const handleChange = (event) => {
        const {name, value} = event.target;
        let newDetails = {...details};
        newDetails[name] = value;
        setDetails(newDetails);
    };

    const handleUpdate = useCallback(
        async e => {
            e.preventDefault();
            handleValidation(address, city, post_code, country)
            try {
                const id = await fetch(MerchDetailsResource.create(), {shirt_size, address, city, post_code, country});
                if (id) {
                    fetch(MerchDetailsResource.detail(), {id: 1})
                    customToast('success', t('merch:details_updated'), setOpen, setSeverity, setMessage);
                }
            } catch (error) {
                onError(error.message);
            }
        },
        [details]
    );

    const handleValidation = (address, city, post_code, country) => {
        if (!address || !city || !post_code || !country){
            onError(t('merch:incomplete_address'));
            throw '';
        }
    };

    return (
        <form id="admin-courses-add" onSubmit={ handleUpdate } >
            <FormControl fullWidth>

                <PreferencesForm data={details} onDataChange={handleChange} />
                
                <DeliveryDetailsForm data={details} onDataChange={handleChange} />

                <CountrySelector data={details} onDataChange={setDetails}  />
                
                <div className={ `new-form-field` }>
                    <Button variant="contained"
                            color="primary"
                            type="submit"
                    >
                        {t('settingsStudentProfilePage:buttonConfirm')}
                    </Button>
                </div>

            </FormControl>
        </form>
    );
};

MerchDetailsForm.propTypes = {
    onError: PropTypes.func,
};

export default MerchDetailsForm;