const fontBoldnesses = [
    {
    value: 'bold',
    label: 'Bold',
    },
    {
    value: 'normal',
    label: 'Normal',
    },
    {
    value: 'light',
    label: 'Light',
    },
  ];

export default fontBoldnesses;