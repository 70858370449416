import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import UserAccountResource from 'resources/user/UserAccountResource';
import UserName from 'utils/localStorage/UserName';

import { Button, FormControl, TextField } from '@mui/material';
import { FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';

import { t } from 'i18n/index';
import i18n from 'i18next';

const PersonalDetailsForm = ({ onError }) => {

    const studentData = useSuspense(UserAccountResource.detail(), {id: 1});

    const [student, setStudent] = useState({...studentData });
    
    const { fetch } = useController();
    
    const prevEmail = studentData.email;

    const handleChange = (event) => {
        const {name, value} = event.target;
        let newStudent = {...student};
        if (name === "preferred_language") {
            newStudent[name] = value;
            
            if (student.preferred_language === "pt" || (student.preferred_language == null && navigator.language.includes("pt"))) {
                newStudent[name] = "en";
                i18n.changeLanguage("en");
            } else {
                newStudent[name] = "pt";
                i18n.changeLanguage("pt");
            }

        } else {
            newStudent[name] = value;
        }

        setStudent(newStudent);
    };

    const handleUpdate = useCallback(
        async e => {
            e.preventDefault();
            const {first_name, last_name, email, preferred_language} = student;
            handleValidation(first_name)
            try {
                const { id, name } = await fetch(UserAccountResource.update(), {id: 1}, {first_name, last_name, email, preferred_language});
                if ( id ) {
                    UserName.write(name);
                } 
            } catch (error) {
                onError(error.message);
            }
        },
        [student]
    );

    const handleValidation = (firstName) => {
        validateFirstName(firstName);
    };

    const validateFirstName = (firstName) => {
        if (!firstName){
            onError(t('settingsStudentProfilePage:firstNameMissing'));
            throw '';
        }
    };

    const {first_name, last_name, wallet_address, email, preferred_language} = student;
    
    const portuguese = preferred_language === "pt" || (preferred_language == null && navigator.language.includes("pt"))

    return (
        <form id="admin-courses-add" onSubmit={ handleUpdate } >
            <FormControl fullWidth>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='first_name'
                        label={t('settingsStudentProfilePage:first_name')}
                        color='warning'
                        value={ first_name }
                        onChange={event => handleChange(event)}
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='last_name'
                        label={t('settingsStudentProfilePage:last_name')}
                        color='warning'
                        value={ last_name }
                        onChange={event => handleChange(event)}
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='wallet_address'
                        label={t('settingsStudentProfilePage:wallet_address')}
                        color='warning'
                        value={ wallet_address }
                        disabled
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='email'
                        label='Email'
                        color='warning'
                        value={ email }
                        disabled={ prevEmail !== '' && prevEmail !== null}
                        onChange={event => handleChange(event)}
                    />
                </div>
                
                <div className={ `new-form-field` }>
                    <FormGroup>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography>{t('settingsStudentProfilePage:portuguese')}</Typography>
                            <FormControlLabel control={<Switch
                                            name='preferred_language'
                                            color="warning"
                                            checked={ !portuguese }
                                            onChange={event => handleChange(event)}
                                            />}
                            />
                            <Typography>{t('settingsStudentProfilePage:english')}</Typography>
                        </Stack>
                    </FormGroup>
                </div>
                
                <div className={ `new-form-field` }>
                    <Button variant="contained"
                            color="primary"
                            type="submit"
                    >
                        {t('settingsStudentProfilePage:buttonConfirm')}
                    </Button>
                </div>
            </FormControl>
        </form>
    );
};

PersonalDetailsForm.propTypes = {
    onError: PropTypes.func,
};

export default PersonalDetailsForm;