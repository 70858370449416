import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import StatusModal from 'components/StatusModal';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { t } from 'i18n/index';

const GiftForm = ({ handleInvoiceDetails, giftData, handleGiftData, senderName, senderEmail }) => {

    useEffect(() => {
        handleGiftData( {...giftData, ["sender_name"]: senderName, ["sender_email"]: senderEmail });  
    }, []);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const handleChange = ({ target: {name, value}}) => {
        handleGiftData( {
        ...giftData,
        [name]: value
        });
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        setLoading(false);
    };

    const handleValidationError = (message) => {
        setError(message);
        handleOpenErrorModal();
        throw ''
    };

    const handleValidation = (giftData) => {
        setLoading(true);
        if(giftData.name === undefined ) {
            handleValidationError(t('giftForm:nameMissing'));
        }
        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if((!giftData.sender_email || !giftData.sender_email.match(validEmail))) {
            handleValidationError(t('giftForm:senderEmailMissing'));
        }
        if (giftData.email === undefined || !giftData.email.match(validEmail)){
            handleValidationError(t('giftForm:emailMissing'));
        }
        setLoading(false);
        return true;
    };

    const handleGiftSubmission = (e, giftData) => {
        e.preventDefault();
        handleValidation(giftData);
        handleInvoiceDetails();
    };

    return (
        <>
            <form onSubmit={ (e) => handleGiftSubmission(e, giftData) } >
                <>
                <h3 className="new-form-section">{t('giftForm:from')}:</h3>
                <TextField label={t('giftForm:senderName')} 
                            name="sender_name"
                            value={ giftData && giftData.sender_name }
                            onChange={ handleChange }
                            fullWidth 
                            margin="normal" 
                            color="warning"
                            disabled={ senderName }>
                </TextField>
                <TextField label={t('giftForm:senderEmail')} 
                            name="sender_email"
                            value={ giftData && giftData.sender_email }
                            onChange={ handleChange }
                            fullWidth 
                            margin="normal" 
                            color="warning"
                            disabled={ senderEmail }>
                </TextField>
                <h3 className="new-form-section">{t('giftForm:to')}:</h3>
                <TextField label={t('giftForm:name')} 
                            name="name"
                            value={ giftData && giftData.name || '' }
                            onChange={ handleChange }
                            fullWidth 
                            margin="normal" 
                            color="warning">
                </TextField>
                <TextField label={t('giftForm:email')} 
                            name="email"
                            value={ giftData && giftData.email || '' }
                            onChange={ handleChange }
                            fullWidth 
                            margin="normal" 
                            color="warning">
                </TextField>
                <TextField multiline
                            minRows={ 3 } 
                            inputProps={{ maxLength: 400 }}
                            label={t('giftForm:message')} 
                            name="message"
                            value={ giftData && giftData.message || '' }
                            onChange={ handleChange }
                            fullWidth 
                            margin="normal" 
                            color="warning">
                </TextField>
                <Button fullWidth
                        variant="contained"
                        color="primary"
                        type="submit">
                    {loading
                    ? `Sending...`
                    : t('giftForm:proceed')
                    }
                </Button>
                </>
            </form>

            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </>
    )
};

GiftForm.propTypes = {
  done: PropTypes.func,
  sellable: PropTypes.bool,
  handleInvoiceDetails: PropTypes.func,
  giftData: PropTypes.object,
  handleGiftData: PropTypes.func,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
};

export default GiftForm;
