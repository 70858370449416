const storage = localStorage;
const key     = 'cc.last_course';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const LastCourse = {
  read:   () => JSON.parse(storage.getItem(key)),
  write: (lastCourse) => {
    const lastCourseString = JSON.stringify(lastCourse);
    storage.setItem(key, lastCourseString);
  },
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LastCourse;