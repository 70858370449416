import React from 'react';
import PropTypes from 'prop-types';
import BadgeStateIcon from '../BadgeStateIcon';
import { onNftScanUrl } from 'utils/urls';

const Title = ({title, showOnChainStatus, id, contract_address, claim_state, badge_tx }) => {

    return (
        <div className="title" >
            { title }
            { showOnChainStatus &&
                <BadgeStateIcon claim_state={claim_state}
                            badge_tx={badge_tx}
                            link_to={() => window.open(onNftScanUrl(contract_address, id), "_blank")}
                            />
            }
        </div>
    );
};

Title.propTypes = {
    title: PropTypes.string,
    showOnChainStatus: PropTypes.bool,
    id: PropTypes.number,
    contract_address: PropTypes.string,
    badge_tx: PropTypes.string,
    claim_state: PropTypes.string,
};

export default Title;
