import React, { useCallback, useState } from 'react';
import { useController,  } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";

import OrganizationResource from 'resources/organization/OrganizationResource';

import Layout from 'components/layouts/index';
import StatusModal from 'components/StatusModal';
import Form from './form';

import handleRoyalties from 'utils/handleRoyalties';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import CoursesList from 'utils/localStorage/CoursesList';
import LastCourse from 'utils/localStorage/LastCourse';

const NewOrganization = () => {
  const { fetch } = useController();
  const history = useHistory();
  
  const { setOpen, setMessage, setSeverity } = useToast();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const pageInfo = {
    name: 'Create your first collection'
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (course, organization) => {
    const { organization_title, organization_admin_role, organization_category } = organization;
    const { title, category } = course;
    
    validateOrganizationTitle(organization_title);
    validateAdminRole(organization_admin_role);
    validateOrganizationCategory(organization_category);
    validateOrganizationGoals(organization);

    validateCourseTitle(title);
    validateCourseCategory(category);
  };

  const validateOrganizationTitle = (title) => {
    if (title === ""){
      setError("Please provide an organization title.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateOrganizationCategory = (organization_category) => {
    if (organization_category === ""){
      setError("Please select a community type.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateAdminRole = (organization_admin_role) => {
    if (organization_admin_role === ""){
      setError("Please tell us your role within your Organization.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateOrganizationGoals = (organization) => {
    const keysToCheck = ["Issue Digital Certificates", "Create NFTs for my events & experiences", "Create Utility for my NFTs", "Other"];
    let hasTrueValue = false;

    for (const key of keysToCheck) {
      if (organization[key]) {
        hasTrueValue = true;
        break;
      }
    }

    if (!hasTrueValue) {
      setError("Please select at least one of the options as to why you are using Crowdclass.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateCourseTitle = (title) => {
    if (title === "") {
        setError("Please provide a course title.");
        handleOpenErrorModal();
        throw '';
    }
  };

  const validateCourseCategory = (category) => {
    if (category === "") {
        setError("Please select a colletion type.");
        handleOpenErrorModal();
        throw '';
    }
  };

  const createFormData = (course, organization) => {
    const formData = new FormData();
    const {title, category, royalties, external_link} = course;
    const {organization_title, organization_external_link, organization_category, organization_admin_role, image_file} = organization;
    const fields = [
      {key: "title", value: organization_title},
      {key: "external_link", value: organization_external_link},
      {key: "category", value: organization_category},
      {key: "admin_role", value: organization_admin_role},
      {key: "external_link", value: organization_external_link},
      {key: "course_title", value: title},
      {key: "course_category", value: category},
      {key: "course_external_link", value: external_link},
    ]
    if (image_file) {
      fields.push({ key: 'image', value: image_file });
    }
    if (organization["Create NFTs for my events & experiences"]) fields.push({key: "goals[]", value: "nfts"});
    if (organization["Issue Digital Certificates"]) fields.push({key: "goals[]", value: "certificates"});
    if (organization["Create Utility for my NFTs"]) fields.push({key: "goals[]", value: "offers"});
    if (organization["Other"]) fields.push({key: "goals[]", value: "other"});

    const courseRoyalties = handleRoyalties(royalties);
    if (courseRoyalties){
      fields.push({key: "course_royalties", value: courseRoyalties})
    }

    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  };

  const handleOnSubmit = useCallback(
    async (e, course, organization) => {
      e.preventDefault();
      handleValidation(course, organization);
      try {
        const formData = createFormData(course, organization);
        const { id, title, course_id, course_title, course_category } = await fetch(OrganizationResource.create(), {}, formData);

        //success!
        if (id){
          await fetch(OrganizationResource.list(), {organization_id: id});
          const coursesList = CoursesList.read();
          const newCourse = {id : course_id, title: course_title, category: course_category, organization_id: id, organization: title,};
          coursesList.push(newCourse);
          CoursesList.write(coursesList);

          const lastCourse = [];
          lastCourse.unshift({ [id]: course_id });
          LastCourse.write(lastCourse);

          history.push(`/organizations/${id}/collections/${course_id}`);
          customToast('success', `New ${course_category} created`, setOpen, setSeverity, setMessage);
        }
      }  catch (error) {
          setError(error.message);
          handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form onSubmit= { handleOnSubmit }/>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
  )
};

export default NewOrganization;