import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

const DraggableElement = ({position, onStop, handleItemChange, item, src, text, imageWidth}) => {
  return (
    <Draggable position={position} onStop={onStop}>
      <div className="dynamic-box" style={{position: 'absolute', width: imageWidth-position.x}}>
        <p onClick={(e) => handleItemChange(e, item)} className={src.align === 'left' && 'left'} style={{color: src.color, fontFamily: src.font, fontSize: src.size, fontWeight: src.weight, margin:0,}}>{text}</p>
      </div>
    </Draggable>
  )
};

DraggableElement.propTypes = {
    position: PropTypes.object,
    onStop: PropTypes.func,
    handleItemChange: PropTypes.func,
    item: PropTypes.string,
    src: PropTypes.object,
    text: PropTypes.string,
    imageWidth: PropTypes.number,
  };

export default DraggableElement;