import LastView from "./localStorage/LastView";
import LastCourse from 'utils/localStorage/LastCourse';
import mobileAndTabletCheck from 'utils/mobileAndTabletCheck';

export default function redirectToView(history, role, coursesList, organizationId, courseId) {
    
  const lastView = LastView.read();
  const organizerView = coursesList ? lastView === "teacher" || (lastView === null && coursesList.length > 0) : null;
  const newLogin = !organizationId && !courseId
  const isMobile = mobileAndTabletCheck();

  if (coursesList.length > 0 && newLogin) {
    organizationId = coursesList[0].organization_id;
    courseId = coursesList[0].id;

    const lastCourse = [];
    lastCourse.unshift({ [organizationId]: courseId });
    LastCourse.write(lastCourse);
  }

  if (isMobile) {
    LastView.write("student");
    history.push(`/profile`);
  } else if (role === 'admin') {
    LastView.write("admin");
    history.push(`/admin/users`);
  } else if (organizerView) {
    LastView.write("teacher");
    history.push(`/organizations/${organizationId}/collections/${courseId}`);
  } else {
    LastView.write("student");
    history.push(`/profile`);
  }
}