import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, ButtonGroup, FormControl, IconButton, InputAdornment } from '@mui/material';
import Filter from './Filter';

const DataTableHeader = ({ organizations,
                           users,
                           collections,
                           rewards,
                           onQueryChange, 
                           queryString, 
                           handleFilter,
                           organizationId,
                           userId,
                           collectionId,
                           rewardId,
                           onClick,
                           organizationView,
                         }) => {

  const alignRight = organizations || users || collections || rewards || onQueryChange;

  const newOfferButton = organizationView && organizationView[0];
  const shareOffersButton = organizationView && organizationView[1];

  return (
    <div className='data-table-header'>
      { organizations &&
        <Filter
            label="Organization"
            items={organizations}
            value={organizationId}
            handleFilter={handleFilter}
        />
      }
      { users &&
        <Filter
              label="User"
              items={users}
              value={userId}
              handleFilter={handleFilter}
        />
      }
      { collections &&
        <Filter
              label="Collection"
              items={collections}
              value={collectionId}
              handleFilter={handleFilter}
              rewardId={rewardId}
        />
      }
      { rewards &&
        <Filter
              label="Collectible"
              items={rewards}
              value={rewardId}
              handleFilter={handleFilter}
              collectionId={collectionId}
        />
      }
      { onQueryChange &&
        <FormControl sx={{'width' : 275}} size="small">
          <TextField label={"Search Participants"}
            type="search"
            size="small"
            value={queryString}
            color="warning"
            onChange={(e) => { onQueryChange(e.target.value) }}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          />
        </FormControl>
      }
      {onClick && 
        <div className={!alignRight && "align-right"}>
          <IconButton  onClick={onClick}>
            <DownloadIcon />
          </IconButton>
        </div>
      }
      {organizationView &&
       <ButtonGroup fullWidth>
          <Button className='table-subHeader-button' sx={{ boxShadow: 1}} onClick={newOfferButton.function} endIcon={<newOfferButton.icon/>}>
            { newOfferButton.text} 
          </Button>
          <Button className='table-subHeader-button' sx={{ boxShadow: 1}} onClick={shareOffersButton.function} endIcon={<shareOffersButton.icon/>}>
            { shareOffersButton.text} 
          </Button>
        </ButtonGroup>
      }
    </div>
  )
};

DataTableHeader.propTypes = {
  organizations: PropTypes.array,
  users: PropTypes.array,
  collections: PropTypes.array,
  rewards: PropTypes.array,
  onQueryChange: PropTypes.func,
  queryString: PropTypes.string,
  handleFilter: PropTypes.func,
  organizationId: PropTypes.number,
  userId: PropTypes.number,
  collectionId: PropTypes.number,
  rewardId: PropTypes.number,
  onClick: PropTypes.func,
  organizationView: PropTypes.array,
};

export default DataTableHeader;