import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';

const icons = {
    unclaimed: NewReleasesIcon,
    offchain: VerifiedIcon,
    onchain: VerifiedIcon,
    minting: NewReleasesIcon,
   };

const colors = {
    unclaimed: '',
    offchain: 'warning',
    onchain: 'success'
   };

const titles = {
    unclaimed: 'Unclaimed',
    offchain: 'Hosted by Crowdclass',
    onchain: ['Claimed on '],
    minting: ['Minting on ']
   };

const BadgeStateIcon = ({claim_state, badge_chain='Polygon', badge_tx, link_to}) => {
    const state = (claim_state === 'onchain' && badge_tx === null) ? 'minting' : claim_state;
    const Icon = icons[state];
    const color = colors[claim_state];
    const title = titles[state];

    if(state === null || state === ''){
        titles
    }

    if(state === 'onchain' || state === 'minting') {
        title.push(badge_chain);
    }

    return (
        <div className={`exclamation-icon`} >
            <Tooltip title={ title } placement='right'>
                <IconButton onClick={link_to} >
                    <Icon color={color} />
                </IconButton>
            </Tooltip>
        </div>
    );
};

BadgeStateIcon.propTypes = {
    claim_state: PropTypes.string,
    badge_chain: PropTypes.string,
    badge_tx: PropTypes.string,
    link_to: PropTypes.func
};

export default BadgeStateIcon;
