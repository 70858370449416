import React from 'react';
import PropTypes from 'prop-types';
import formattedDate from 'utils/formattedDate';
import { rewardType, activityType } from 'utils/constants';
import MetadataPreview from './MetadataPreview';
import { t } from 'i18n/index';

const systemMetadataAvailable = (isWhitelabel) => {
  return !isWhitelabel;
};

const Metadata = ({activity_title, activity_type, reward_type, organization, created_at, claimed_at, metadata, isWhitelabel}) => {
    const showSystemMetadata = systemMetadataAvailable(isWhitelabel);

    return (
      <div className="metadata-container">
        { showSystemMetadata &&
          <>
            { activity_title &&
              <MetadataPreview title={t('metadata:metadata_ActivityTitle')} value={activity_title} />
            }

            { activity_type &&
              <MetadataPreview title={t('metadata:metadata_ActivityType')} value={activityType[activity_type] || activity_type} />
            }

            { reward_type &&
              <MetadataPreview title={t('metadata:metadata_TokenType')} value={rewardType[reward_type.toUpperCase()].label} />
            }

            { organization &&
              <MetadataPreview title={t('metadata:metadata_IssuerName')} value={organization} />
            }

            { created_at &&
              <MetadataPreview title={t('metadata:metadata_IssuedAt')} value={formattedDate(created_at)} />
            }

            { claimed_at &&
              <MetadataPreview title={t('metadata:metadata_ClaimedAt')} value={formattedDate(claimed_at)} />
            }
          </>
        }

        { metadata && metadata.map(({ key, value }) => (
          <MetadataPreview key={key} title={key} value={value} />
          ))
        }
      </div>
    );
};

Metadata.propTypes = {
  activity_title: PropTypes.string,
  activity_type: PropTypes.string,
  reward_type: PropTypes.string,
  organization: PropTypes.string,
  created_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  claimed_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  metadata: PropTypes.array,
  isWhitelabel: PropTypes.bool,
  };

export default Metadata;
