import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ActionMenu = ({ id, organizationId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        className="orange"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        className="badge-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'badge-actions-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${id}/edit` } disableRipple>
          <EditIcon />
          Edit Collection Settings
        </MenuItem>
        <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${id}/participants/new` } disableRipple>
          <AddCircleOutlineIcon />
          Add Contact to Whitelist
        </MenuItem>
        <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${id}/participants/import` } disableRipple>
          <FileUploadIcon />
          Import Contacts to Whitelist
        </MenuItem>
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  id: PropTypes.number,
  organizationId: PropTypes.number,
  category: PropTypes.string,
  invite: PropTypes.string
};

export default ActionMenu;