import SearchRewardInstanceResource from "resources/organization/SearchRewardInstanceResource";

import LastView from "utils/localStorage/LastView";

export const findRewardInstance = (paymentIntentId, allowedAttempts, requestInterval, fetch, history, handleError) => {
    let attempts = 0;
    const poll = setInterval(async () => {
      try {
        const response = await fetch(SearchRewardInstanceResource.detail(), {organization_id: 1,  id: paymentIntentId});
        if (response.order_id === response.order_id_main) {
          LastView.write("student");
          history.push(`/profile`, { from: "claim page" });
          clearInterval(poll);
        } else if (attempts >= allowedAttempts) {
          handleError();
          clearInterval(poll);
        } else {
          attempts++;
        }
      } catch (error) {
        if (attempts >= allowedAttempts) {
          handleError();
          clearInterval(poll);
        } else {
          attempts++;
        }
      }
    }, requestInterval);
  };
