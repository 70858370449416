import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/admin/OrganizationResource';
import Form from './form';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import Dialog from '@mui/material/Dialog';
import { useController } from '@rest-hooks/react';

const CreateOrganizationModal = ({ open, onClose, handleError }) => {

  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();

  const handleValidation = (formData) => {
    validateTitle(formData);
    validateCourseTitle(formData)
    validateEmail(formData);
  };
  const validateTitle = (formData) => {
    if (formData.get("title") === ""){
      handleError("Please tell us your organization name.");
    }
  };

  const validateCourseTitle = (formData) => {
    if (formData.get("course_title") === ""){
      handleError("Please tell us your organization 1st Crowdclass event title.");
    }
  };

  const validateEmail = (formData) => {
    if (formData.get("admin_email") === ""){
      handleError("Please tell us your organization admin email.");
    }
  };

  const handleCreateOrganization = useCallback(
    async e => {
      e.preventDefault();
      const formData = new FormData(e.target);
      handleValidation(formData);
      try {
        const { id, title } = await fetch(OrganizationResource.create(), formData);
       
        // success!
        if (id){
          fetch(OrganizationResource.list());
          customToast('success', `Organization ${title} created`, setOpen, setSeverity, setMessage);
          onClose();
        }
      }  catch (error) {
        handleError(error.message);
      }
    },
    [fetch],
  );
  return (
    <Dialog open={open} onClose={ onClose }>
      <Form onSubmit={handleCreateOrganization} />
    </Dialog>
  );
}

CreateOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleError: PropTypes.func,
};

export default CreateOrganizationModal;