import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SignUpForm from 'components/authForms/SignUpForm';
import LogInForm from 'components/authForms/LogInForm';
import ResetForm from 'components/authForms/ResetForm';
import DialogContent from '@mui/material/DialogContent';
import Footer from './Footer';
import Title from './Title';
import { offerType } from 'utils/offersFormHelper';

import Dialog from '@mui/material/Dialog';

const AuthModal = ({open, onClose, done, resetToken, sellable, handleSuccessfulAuth, tokenId, offer}) => {

  const [logIn, setLogIn] = useState(false);
  const [preResetDescription, setPreResetDescription] = useState(true);

  const Form = resetToken ? ResetForm : logIn ? LogInForm : SignUpForm;

  return (
    <Dialog open={open} onClose={ onClose }>
        
        <Title resetToken={ resetToken }
               preResetDescription={ preResetDescription }
               offer={ offer }
               logIn={ logIn } />

        <DialogContent dividers>


        <Form done={done} 
              resetToken={ resetToken }
              handleDescription={ setPreResetDescription }
              sellable={ sellable }
              handleSuccessfulAuth={ handleSuccessfulAuth }
              tokenId={ tokenId }
              hideEmailSignUp={ offer && offer.offer_type === offerType.external }
              hideWalletConnect={ !!resetToken } />

        {!resetToken && <Footer logIn={logIn} handleLogInChange={() => setLogIn(!logIn)}/>}
  
      </DialogContent>
    </Dialog>
  );
}

AuthModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  done: PropTypes.func,
  resetToken: PropTypes.string,
  sellable: PropTypes.bool,
  handleSuccessfulAuth: PropTypes.func,
  tokenId: PropTypes.string,
  offer: PropTypes.bool,
};

export default AuthModal;
