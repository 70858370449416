import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import logo from 'assets/images/logo-crowdclass.svg';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import WidgetsIcon from '@mui/icons-material/Widgets';

const course_links = [
  { text: 'Organizations',  Icon: BusinessIcon, link: '/admin/organizations' },
  { text: 'Users',  Icon: PeopleIcon, link: '/admin/users' },
  { text: 'Collections', Icon: WidgetsIcon, link: '/admin/collections' },
]

const Admin = ({ activeMenuItem=0 }) => {
  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>
      <List>

        {course_links.map(({text, Icon, link}, index) => (
            <ListItemButton key={text}
                            selected={ index == activeMenuItem }
                            component={Link}
                            to={ link } >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={ text } />
            </ListItemButton>
        ))}
        
      </List>
    </div>
  );
};

Admin.propTypes = {
  activeMenuItem: PropTypes.number,
};

export default Admin;