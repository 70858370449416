import React from 'react';

import { onChainUrl, onNftScanUrl, publicReward } from 'utils/urls';
import { rewardType } from 'utils/constants';
import moment from 'moment';

import SailingIcon from '@mui/icons-material/Sailing';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';

export const rewardInstancesColumns = [
    { 
      field: 'fullName',
      name: 'Participant',
      sortable: true,
      width: "10%",
      selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
    },
    { 
      field: 'email',
      name: 'Email',
      sortable: true,
      width: "15%",
      selector: (row) => `${row.email}`
    },
    { 
      field: 'walletAddress',
      name: 'Wallet Address',
      sortable: true,
      width: "15%",
      selector: (row) => `${row.wallet_address == null ? "unknown" : row.wallet_address}`
    },
    { 
      field: 'category',
      name: 'Reward Id',
      sortable: true,
      width: "10%",
      selector: (row) => row.reward_id
    },
    { 
      field: 'category',
      name: 'NFT Type',
      sortable: true,
      width: "15%",
      selector: (row) => row.category,
      format: (row) => rewardType[row.category.toUpperCase()].label
    },
    { 
      field: 'title',
      name: 'Title',
      sortable: true,
      width: "15%",
      selector: (row) => row.title
    },
    { 
      field: 'claimed',
      name: 'Status',
      sortable: true,
      width: "15%",
      selector: (row) => row.claim_state,
    },
    { 
      field: 'tx', 
      name: 'TX ID',
      sortable: true, 
      width: "15%",
      selector: (row) => row.tx
    },
    { 
      field: 'created_at',
      name: 'Date issued',
      sortable: true,
      width: "15%",
      selector: (row) => row.created_at,
      format: (row) => `${moment(row.created_at).format('lll')}`
    },
    { 
      field: 'claimed_at',
      name: 'Date claimed',
      sortable: true,
      width: "15%",
      selector: (row) => row.claimed_at,
      format: (row) => row.claimed_at ? `${moment(row.claimed_at).format('lll')}` : ''
    },
    {
      name: 'Actions',
      cell: (row) =>  
      <>
      <Tooltip title='View on Crowdclass' >
        <PreviewIcon onClick={() => window.open(publicReward(row.id), "_blank")}  />
      </Tooltip>
  
      <Tooltip title='View on OpenSea'>
        <SailingIcon onClick={() => window.open(onNftScanUrl(row.contract_address, row.id), "_blank")} /> 
      </Tooltip>
  
      <Tooltip title='View on PolygonScanner' >
        <CardMembershipIcon onClick={() => window.open(onChainUrl(row.contract_address, row.id), "_blank")} />
      </Tooltip>
      </> ,
      ignoreRowClick: true,
          allowOverflow: true,
          button: true,
      style: {
        cursor: 'pointer',
      }    
    }
  ];