import React from "react";
import PropTypes from "prop-types";

import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from "@mui/icons-material/Delete";

const Footer = ({ offer, onDelete }) => {
  return (
    <div className={`new-form-field-footer`}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        endIcon={!offer.id ? "" : <SaveIcon />}
      >
        {!offer.id ? "Create" : "Save"}
      </Button>
      {offer.id && (
        <Button
          variant="contained"
          disabled={offer.redeemed !== 0}
          onClick={onDelete}
          endIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}
    </div>
  );
};

Footer.propTypes = {
  offer: PropTypes.object,
  onDelete: PropTypes.func,
};

export default Footer;
