import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useController } from '@rest-hooks/react';

import UserAccountResource from 'resources/user/UserAccountResource';
import WalletResource from 'resources/user/WalletResource';
import ClaimResource from 'resources/public/ClaimResource';

import Layout from 'components/layouts/index';
import ShareRewardModal from 'components/ShareRewardModal';
import StatusModal from 'components/StatusModal';
import Footer from 'components/Footer';

import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';
import OnChainModal from './OnChainModal';
import ProfileTabPanel from './ProfileTabPanel';
import logo from 'assets/images/logo.png';

import { claimState } from 'utils/constants';
import { useConnectWalletModal, useStatusModal, useShareModal} from 'utils/profileModals';
import isMobileDevice from 'utils/mobileAndTabletCheck';
import CoursesList    from 'utils/localStorage/CoursesList';

import LinearProgress from '@mui/material/LinearProgress';

import { find } from 'lodash';
import { t } from 'i18n/index';

const pageInfo = {
  label: t('studentProfile:collectorsPortal_Profile')
};

const Profile = () => {
  const { connectIsOpen, openConnectModal, closeConnectModal } = useConnectWalletModal();
  const { statusIsOpen, openStatusModal, closeStatusModal } = useStatusModal();
  const { shareIsOpen, openShareModal, closeShareModal } = useShareModal();

  const [loading, setLoading] = useState(true);
  const [celebration, setCelebration] = useState(false);
  const [error, setError] = useState(null);
  const [claimableId, setClaimableId] = useState(null);
  const [connectLater, setConnectLater] = useState(false);
  const [walletAddress, setWalletAddress] = useState(false);
  const [account, setAccount] = useState({});
  const [rewards, setRewards] = useState([]);
  const [claimed, setClaimed] = useState ([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [activeReward, setActiveReward] = useState({});
  const [claimStarted, setClaimStarted] = useState(false);
  const [labels, setLabels] = useState([]);
  const [updatingWallet, setUpdatingWallet] = useState(false);

  const { fetch } = useController();
  const history = useHistory();

  useEffect(() => fetchData(true), []);

  useEffect(() => fetchData(false), [walletAddress]);

  const fetchData = async (firstMount) => {
    const data = await fetch(UserAccountResource.detail(), {id: 1})
    setAccount(data);
    setRewards(data.rewards);
    setWalletAddress(data.wallet_address);
    customizePageInfo(data.wallet_address, data.name);

    const unclaimed = data.rewards
      .filter( ({claim_state }) => claim_state === claimState.UNCLAIMED.value )
      .sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 })
    setUnclaimed(unclaimed);

    const claimed = data.rewards
      .filter( ({claim_state}) => claim_state !== claimState.UNCLAIMED.value )
      .sort(({claimed_at: a}, {claimed_at: b} ) => {return a < b ? 1 : -1 })
    setClaimed(claimed);

    handleLabels(claimed, unclaimed)

    if (firstMount) {

      const fromClaimPage = history.location.state && history.location.state.from == "claim page";
      const unclaimedRewardId = unclaimed.length > 0 && unclaimed[0].id;

      if ( fromClaimPage && unclaimedRewardId ) {
        setClaimStarted(true)
        setClaimableId(unclaimedRewardId)
        handleClaim(unclaimedRewardId, data.rewards, data.wallet_address, connectLater);
        history.push("/profile")
      }
    }
    setLoading(false);
  };

  const customizePageInfo = (walletAddress, name) => {
    if (walletAddress) {
      pageInfo["description"] = walletAddress;
      pageInfo["action"] = false
    } else {
      pageInfo["action"] = true;
      pageInfo["description"] = null;
    }
    pageInfo['name'] = name;
  };

  const handleResetStates = () => {
    closeConnectModal();
    closeShareModal();
    setCelebration(false);
    setClaimableId(null);
    setClaimStarted(false)
    setActiveReward(null);
    setUpdatingWallet(false);
  };

  const handleConnect = async (signature) => {
    try {
      const { wallet_address } = await fetch(WalletResource.update(), { id: account.id }, signature);
      setWalletAddress(wallet_address);

      // success!
      if (wallet_address && claimStarted){
          handleClaim(claimableId, rewards, wallet_address, true);
      }
    }  catch (error) {
      setError(error.message);
      openStatusModal;
    }
  };

  const handleConnectLater  = () => {
    const offChainReward = find(rewards, { 'id': claimableId }).claim_state === claimState.OFFCHAIN.value;
      if (offChainReward) {
        handleResetStates();
      } else {
        setConnectLater(true);
        setUpdatingWallet(false);
        closeConnectModal();
        handleClaim(claimableId, rewards, walletAddress, true);
      }
    };

  const handleLabels = (claimed, unclaimed) => {
    const newLabels = [];
    if (claimed.length > 0) {
      newLabels.push(`${t('studentProfile:collectorsPortal_Claimed')} (${claimed.length})`);
    }
    if (unclaimed.length > 0) {
      newLabels.push(`${t('studentProfile:collectorsPortal_Unclaimed')} (${unclaimed.length})`);
    }
    setLabels(newLabels)
  }

  const handleClaim = async (rewardId, rewards, walletAddress, connectLater) => {
    setClaimableId(rewardId);
    setClaimStarted(true)
    setActiveReward(rewards.find(reward => reward.id === rewardId ));
    if ( walletAddress || connectLater ){
      const { claim_state } = await fetch(ClaimResource.update(), { id: rewardId });
      if (claim_state !== claimState.UNCLAIMED.value){
        openShareModal();
        setCelebration(true);
        fetchData(false);
      }
    } else {
      openConnectModal();
    }
  };

  const coursesList = CoursesList.read();
  const canCreateFirstCollection = coursesList && coursesList.length === 0 && !isMobileDevice();

  return (
    <>{loading?
    <img src={logo} className="pulse center-absolutely"/>
    :
    <Layout context='student'
            pageInfo={ pageInfo }
            cta={ updatingWallet ?
                    <LinearProgress sx={{ minWidth: 300 }} color="warning"/>
                  :
                    <ConnectWalletButton challengeType={ 'connect' } done={ handleConnect } />
                }
                >

      <OnChainModal open={ connectIsOpen }
                    onSubmit={ handleConnect }
                    onSkip={ handleConnectLater }
                    onClose={ claimStarted ? null : closeConnectModal }
                    />

      <ShareRewardModal open={ shareIsOpen }
                        description={
                          <div className={`description-share-modal`} >
                            <p className={`share-modal-p`} >{t('shareRewardModal:title')}</p>
                            <p className={`share-modal-label`} > {t('shareRewardModal:description')} {activeReward && activeReward.title} </p>
                          </div>
                        }
                        reward={ activeReward }
                        showCelebration={ celebration }
                        onClose={ handleResetStates }
                        viewOnPublic={true}
                        />

      { error && <StatusModal message={ error } open ={ statusIsOpen } onClose={ closeStatusModal }/>}

      <ProfileTabPanel labels={labels} claimed={claimed} unclaimed={unclaimed} onClaim={ (id) => handleClaim(id, rewards, walletAddress, connectLater) } />

      {canCreateFirstCollection &&
        <Footer />
      }

    </Layout>
    }</>
)
};

export default Profile;
