import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

const TableButton = ({title, Icon, link, action, disabled}) => {
  
const history = useHistory();

  return (
    <Tooltip title={ title } placement='right'>
      <IconButton onClick={() => link ? history.push(link) : action} disabled={disabled}>
          <Icon color={disabled ? "gray" : "warning"} fontSize='small'/>
      </IconButton>
    </Tooltip>

  )
};

TableButton.propTypes = {
    title: PropTypes.string,
    Icon: PropTypes.string,
    link: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
};

export default TableButton;