export const offerType = {
    internal: 'InternalOffer',
    external: 'ExternalOffer',
};

export const contentType = {
    text: 'TextOffer',
    reveal: 'RevealOffer',
};

export const emptyOfferDefinition = {
    title: '',
    description: '',
    redeem_limit: '',
    file: null,
    filename: '',
    end_date: null,
    offer_type: '',
    content_type: '',
};

export const emtpyInternalOffer = {
    reward_id: null,
    reward_title: "",
    course_id: null,
    course_title: "",
};

export const emtpyExternalOffer = {
    blockchain: null,
    contract_address: null,
    eligible_attributes: ''
};

export const emtpyTextOffer = {
    content_text: '',
    content_text_link: ''
};

export const emptyRevealOffer = {
    content_collections: [],
    reveal_file: null,
    reveal_filename: '',
    reveal_image_attached: false
};

export const defaultOffer = {
    ...emptyOfferDefinition,
    ...emtpyInternalOffer,
    ...emtpyExternalOffer,
    ...emtpyTextOffer,
    ...emptyRevealOffer,
};

export const sortingOptions = (a, b) => {
    const courseTitleComparison = a.course_title.localeCompare(b.course_title);
    if (courseTitleComparison !== 0) {
        return courseTitleComparison;
    }
    return a.title.localeCompare(b.title);
};