import React from 'react';

import { t } from 'i18n/index';

const Onboarding = () => {
  return (
    <div className='center onboarding'>

        <h4>{t('publicClaimPage:onboardingSubtitle1')}</h4>
        <p>{t('publicClaimPage:onboardingText1')}</p>

        <h4>{t('publicClaimPage:onboardingSubtitle2')}</h4>
        <p>{t('publicClaimPage:onboardingText2')}</p>

    </div>
  )
}

export default Onboarding
