import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class InviteResource extends AuthenticatedResource {
  static urlRoot = '/invites' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly organization: string = '';

  pk() {
    return this.id?.toString();
  }

}
