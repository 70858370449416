import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class OrganizationOfferResource extends AuthenticatedResource {
  static urlRoot = 'organizations/:organization_id/offers' as const

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

}