import React from 'react';
import PropTypes from 'prop-types';

import { useLogout } from 'utils/useLogout';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const StatusModal = ({ open, message, onClose, table, feedback, handleLogin }) => {
  
  const logout = useLogout();

  let tableElement;

  if (typeof message != "object" ){
    table = null
  }

  if ( table ) {
    tableElement = (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} size='small' aria-label="a dense table">
          <TableBody>
              { message[0].includes("added") ?
              <>
                <TableRow key={0}>
                    <TableCell>
                      Success
                    </TableCell>
                    <TableCell>
                      {message[0]}
                    </TableCell>
                </TableRow>
                {message.slice(1).map((item, key) => (
                  <TableRow key={key}>
                      <TableCell>
                        {`${item}`.split(':')[0]}
                      </TableCell>
                      <TableCell>
                        {`${item}`.split(': ')[1]}
                      </TableCell>
                  </TableRow>
                ))}
              </>
              :
              <>
                {message.map((item, key) => (
                  <TableRow key={key}>
                      <TableCell>
                        {`${item}`.split(':')[0]}
                      </TableCell>
                      <TableCell>
                        {`${item}`.split(': ')[1]}
                      </TableCell>
                  </TableRow>
                ))}
              </>
              }
            </TableBody>
        </Table>
      </TableContainer>
    )
  }
  
  let loginButton;
  if (handleLogin) {
    loginButton = (
      <div className='status-modal-login-button'>
        <Button
          variant="contained"
          onClick={() => {
            logout(false, false)
            onClose();
            handleLogin();
          }}
        >
          Log in
        </Button>
      </div>
    )
  }
   
  return (
    <>
      <Dialog open={open} fullWidth={ true } maxWidth='sm'>
      <DialogTitle>
        {!feedback ? 
        <>
          <ErrorIcon sx={{ color: 'red' }} style={{ "verticalAlign": "middle", "paddingRight": "2%"}} />
          Form Error
        </> : <>
          <ErrorIcon sx={{ color: 'blue' }} style={{ "verticalAlign": "middle", "paddingRight": "2%"}} />
          Information 
        </>
        }
        {onClose && (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
            >
              <CloseIcon />
          </IconButton>
        )} 
      </DialogTitle>
      <DialogContent dividers>

        <DialogContentText>
          { !table && message }
          { table  && tableElement }
          { handleLogin && loginButton }
        </DialogContentText>
      </DialogContent>
    </Dialog>
    </>
  );
}

StatusModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  table: PropTypes.bool,
  feedback: PropTypes.bool,
  handleLogin: PropTypes.func,
};

export default StatusModal;