import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomBoard from './CustomBoard';

import 'assets/css/template.css';

const TemplateModal = ({ imgSource, open, title, onClose, onSave }) => {

  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [imageRealHeight, setImageRealHeight] = useState('');
  const [imageRealWidth, setImageRealWidth] = useState('');

  const handleImageDimensions = () => {
    let img = document.getElementById("modal-image");
    setImageWidth(img.width);
    setImageHeight(img.height);
    setImageRealWidth(img.naturalWidth);
    setImageRealHeight(img.naturalHeight);
  };

  return (
    <Dialog open={open}
            fullScreen
            >
      <DialogTitle>
        { title }
        {onClose && (
          <Tooltip title="Close">
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'black',
              }}
              >
                <CloseIcon fontSize="large" color="warning"/>
            </IconButton>
          </Tooltip>
        )} 
      </DialogTitle>
      <DialogContent style={ { textAlign: 'center'} } dividers>
        <CustomBoard onSave={onSave} onClose={onClose} imageRealWidth={imageRealWidth} imageRealHeight={imageRealHeight} imageWidth={imageWidth} imageHeight={imageHeight} />
        <img onLoad={handleImageDimensions} src={imgSource} id='modal-image' />
      </DialogContent>
    </Dialog>
  );
}

TemplateModal.propTypes = {
  imgSource: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  reward: PropTypes.object,
  showCelebration: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default TemplateModal; 