import React from 'react'
import PropTypes from 'prop-types'

import TextField from "@mui/material/TextField";

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';

const EndDate = ({ offer,
                   onChange,
                }) => {
    return (
        <div className={`new-form-field`}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker label="Expiration date. Leave empty for no expiration."
                                value={ offer.end_date ? offer.end_date : null }
                                onChange={ onChange }
                                minDate={ moment().toDate() }
                                renderInput={ (params) => <TextField {...params} color='warning' fullWidth /> }
                />
            </LocalizationProvider>
        </div>
    );
};

EndDate.propTypes = {
    offer: PropTypes.object,
    onChange: PropTypes.func,
};

export default EndDate;