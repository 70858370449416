import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'react-data-table-component';
import DataTableHeader from './DataTableHeader';
import { customStyles } from './customStyles';

const DataSelectionTable = ({records, 
                             columns, 
                             checkboxSelectRows, 
                             onSelectChange,
                             organizations,
                             users,
                             collections,
                             rewards,
                             onQueryChange, 
                             queryString, 
                             handleFilter,
                             userId,
                             organizationId,
                             collectionId,
                             rewardId,
                             onClick,
                             organizationView,
                             actionsButton,
                            }) => {
  return (
        <DataTable data={records}
                   columns={columns}
                   pagination
                   paginationRowsPerPageOptions={[20,50,100]}
                   fixedHeader
                   fixedHeaderScrollHeight='auto'
                   selectableRows={checkboxSelectRows}
                   selectableRowsHighlight
                   onSelectedRowsChange={ onSelectChange }
                   clearSelectedRows
                   customStyles={customStyles}
                   subHeader
                   subHeaderComponent={ 
                    <DataTableHeader organizations={organizations}
                                     users={users}
                                     collections={collections}
                                     rewards={rewards}
                                     onQueryChange={onQueryChange}
                                     queryString={queryString}
                                     handleFilter={handleFilter}
                                     organizationId={organizationId}
                                     userId={userId}
                                     collectionId={collectionId}
                                     rewardId={rewardId}
                                     onClick={onClick}
                                     organizationView={organizationView}
                                     />
                   }
                   actions={actionsButton}
        />
  )
};

DataSelectionTable.propTypes = {
  records: PropTypes.array,
  columns: PropTypes.array,
  checkboxSelectRows: PropTypes.func,
  onSelectChange: PropTypes.func,
  organizations: PropTypes.array,
  users: PropTypes.array,
  collections: PropTypes.array,
  rewards: PropTypes.array,
  onQueryChange: PropTypes.func,
  queryString: PropTypes.string,
  handleFilter: PropTypes.func,
  organizationId: PropTypes.number,
  userId: PropTypes.number,
  collectionId: PropTypes.number,
  rewardId: PropTypes.string,
  onClick: PropTypes.func,
  actionsButton: PropTypes.func,
  organizationView: PropTypes.array,
};

export default DataSelectionTable;