import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const MobileStepper = ({ activeStep, steps }) => {

    const progress = ( (activeStep + 1) / steps.length) * 100;
    const activeLabel = steps[activeStep];
    const nextLabel = steps[activeStep + 1];

    return (
        <div className='mobile-checkout-stepper'>
            <div className='mobile-checkout-stepper-progress'>
                <CircularProgressWithLabel value={progress} 
                                   activeStep={activeStep + 1}
                                   totalSteps={steps.length}
                                   />
            </div>
            <div className='mobile-checkout-stepper-label'>
                <h4>{activeLabel}</h4>
                { steps[activeStep + 1] && <p>Next: {nextLabel}</p> }
            </div>
        </div>

    );
}

MobileStepper.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.array,
};

export default MobileStepper;