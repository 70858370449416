import React, { useState } from 'react';
import { useSuspense } from '@rest-hooks/react';

import OrganizationResource from 'resources/admin/OrganizationResource';
import CreateOrganizationModal from './create/CreateOrganizationModal';
import EditOrganizationModal from './edit/EditOrganizationModal';
import StatusModal from 'components/StatusModal';
import DataTable from './DataTable';

import Button from '@mui/material/Button';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';

const pageInfo = {
  label: "Admin",
  name: "Organizations",
}

const AdminUsers = () => {

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [activeOrg, setActiveOrg] = useState({name:"", id: "", modules: []});

  const organizations = useSuspense(OrganizationResource.list());
  
  const handleOpenCreateModal = () => setOpenCreateModal(true);

  const handleOpenEditModal = () => setOpenEditModal(true);

  const handleCloseModals = () => {
    setOpenCreateModal(false);
    setOpenEditModal(false);
  };

  const handleOpenErrorModal = () => setOpenErrorModal(true);

  const handleCloseErrorModal = () => setOpenErrorModal(false);
  
  const handleError = (message) => {
    setError(message);
    handleOpenErrorModal();
    throw '';
  }

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            statOverview={
              <StatCard type='number'
                        highlight={ organizations.length }
                        title='Organizations'
              />
            } >

      <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>
        Add Organization
      </Button>

      <CreateOrganizationModal open={ openCreateModal }
                          onClose={ handleCloseModals }
                          handleError={ handleError } 
                          />

      <EditOrganizationModal open={ openEditModal }
                          onClose={ handleCloseModals }
                          handleError={ handleError } 
                          activeOrg={ activeOrg } 
                          handleActiveOrg ={ setActiveOrg }
                          /> 

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
    
      <DataTable records={ organizations } 
                 handleActiveOrg={ setActiveOrg }
                 handleOpenEditModal = { handleOpenEditModal }
                 />

    </Layout>
)};

export default AdminUsers;