import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n/index';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';


const Description = ({description}) => {
    if (!description) return null;

    return (
        <div className="description-container">
            <h2>{t('publicClaimPage:description')}</h2>
            <ReactMarkdown>{description}</ReactMarkdown>
        </div>
    );
};

Description.propTypes = {
    description: PropTypes.string
}

export default Description;
