import React, { useState } from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import RewardInstanceResource from 'resources/organization/RewardInstanceResource';
import ThemeResource  from 'resources/public/ThemeResource';
import SearchOffersResource from 'resources/user/SearchOfferResource';

import SocialMetaTags from 'components/SocialMetatags';
import PublicLayout from 'components/layouts/Public';
import RewardPreview from 'components/RewardPreview/index';

import { Divider } from '@mui/material';
import PreviewAction from './PreviewAction';

import { publicReward } from 'utils/urls';
import { t } from 'i18n/index';

import 'assets/css/reveal.css';

const PublicReward = () => {
  const [reveal, setReveal] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);

  const params  = useParams();
  const tokenId = parseInt(params.tokenId);
  const reward = useSuspense(RewardInstanceResource.detail(), {id: tokenId, course_id: 1});
  const theme = useSuspense(ThemeResource.detail(), {id: reward.organization.id});

  let offers = [];
  if (reward.is_owner) {
    offers = useSuspense(SearchOffersResource.list(), { id: reward.id });
  }

  const customTheme =  theme.state === 'final' ? theme : null;

  const handleRedeem = (tokens) => {
    console.log('tokens:',tokens);
    setReveal(true);
    setTokens(tokens);
    setInterval(() => {
      setPlayVideo(true);
    }, 7000);
  }

  return (
    <>
    <PublicLayout theme={ customTheme } >
      <SocialMetaTags title={ reward.title }
                      description={ reward.description }
                      image_url={ reward.image_url }
                      external_url={reward.external_url}
      />

      { !customTheme &&
        <Divider variant='fullWidth' sx={{mt: 2}}/>
      }

      <RewardPreview title={ reward.title }
                     organization={reward.organization.title}
                     actions={<PreviewAction reward={reward}
                                             offers={offers}
                                             onRedeem={ handleRedeem } />}
                     image_url={ reward.image_url }
                     activity_link={ reward.course_link }
                     description={ reward.description }
                     metadata={ reward.metadata }
                     created_at={ reward.created_at }
                     claimed_at={ reward.claimed_at }
                     reward_type={reward.category}
                     claim_state={ reward.claim_state }
                     badge_tx={ reward.badge_tx }
                     activity_title={ reward.course }
                     activity_type={ reward.course_category }
                     id={ reward.id }
                     video={ reward.video_url }
                     media_type={ reward.media_type }
                     isWhitelabel={ !!customTheme }
                     showOnChainStatus={ true }
                     showDownloadButtons={ true }
      />
      {reveal && <div id="explosion"></div>}

    </PublicLayout>

    { reveal &&
      <div id="backdrop">
        <div className="grid">
        { tokens && tokens.map(({id, title, image_url, video_url}) => {
              return <div key={id} className='revealed-token'>
                { (!video_url || !playVideo) ?
                  <div className='img-wrapper'>
                    <img src={image_url} />
                  </div>
                :
                  <div className='video-wrapper'>
                    <video autoPlay muted playsInline poster={image_url}>
                      <source src={video_url} type="video/mp4" />
                    </video>
                  </div>
                }
                { playVideo &&
                <>
                  <div className='title'>{title}</div>
                  <div className='link'>
                    { video_url ?
                      <a className='button' href={ publicReward(id) } target='_blank' rel="noreferrer">
                        { t('claimState:collectorsPortal_Reward_Claimed_OnChain') }
                      </a>
                      :
                      <p className='future'> {t('reveal:revealSoon')} </p>
                    }
                  </div>
                </>
                }
              </div>
            })}
        </div>
      </div>}
    </>
  );
};

export default PublicReward;
