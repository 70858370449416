const columns = {
  "orders.csv": `Order ID,NFT Title,User ID,User Name,User Email,Payment Provider,Amount,Total Price,Vat,Address,Country,Created At,Completed At`,
  "claimed_nfts.csv": `Title #Serial_Number,NFT Type,User Name,User Email,User Wallet,Status,TX Id,Date Issued,Date Claimed`,
  "redeemed_offers.csv": `Offer Id,Redeem State,Offer Title,Reward Id,Reward Title,User Id,User Name,User Email,Created At`,
};

const generateCsvData = (response, csvColumns) => {
  const csvRows = response.map((element) => {
    const values = Object.values(element).map((value) => `"${value}"`);
    return values.join(",");
  });
  return `${csvColumns}\n${csvRows.join("\n")}`;
};

const downloadFile = (data, filename, type) => {
  const blob = new Blob([data], { type: type });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
};

export const downloadCSV = (data, filename, type) => {
  const csvColumns = columns[filename];
  const csvData = generateCsvData(data, csvColumns);
  downloadFile(csvData, filename, type)
};

export const downloadFileFromUrl = (url, filename, type) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      downloadFile(blob, filename, type);
    });
};
