import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import logo from 'assets/images/logo-crowdclass.svg';
import { navLinks } from 'utils/navLinks';

import { t } from 'i18n/index';

const Student = ({ activeMenuItem=0 }) => {

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>
      <List>
        <ListItem>
          <ListItemText
            primary={t('studentProfile:collectorsPortal_Profile')}
            primaryTypographyProps={{
              fontSize: 15,
              fontWeight: 'bold',
              lineHeight: '20px',
              mb: '2px',
            }}
          />
        </ListItem>

        {navLinks.map(({text, Icon, link, external_link}, index) => {
          const listComponent = link ? Link : "a";
          
          return (
            <ListItemButton key={text}
                            component={ listComponent }
                            to={ link }
                            href={ external_link }
                            target={ external_link && "_blank"}
                            selected={ index == activeMenuItem }
                            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={ text } />
            </ListItemButton>
        )})}
      </List>
    </div>
  );
};

Student.propTypes = {
  activeMenuItem: PropTypes.number,
};

export default Student;