import React from 'react'
import PropTypes from 'prop-types'
import StringButton from 'components/buttons/StringButton';
import { t } from 'i18n/index';

const Footer = ({logIn, handleLogInChange}) => {
  return (
    <div>
        {logIn === false ?
            <p className={`auth-modal-p`} >{t('signUpPage:signUpModal_AlreadyHaveAccount')}
              <StringButton action={handleLogInChange} description={t('signUpPage:signUpModal_SignIn')} />
            </p>
          :
            <p className={`auth-modal-p`} >{t('loginPage:loginModal_NoAccountFirst')}
              <StringButton action={handleLogInChange} description={t('loginPage:loginModal_NoAccountSecond')} />
            </p>
        }
    </div>
  )
};

Footer.propTypes = {
    logIn: PropTypes.bool,
    handleLogInChange: PropTypes.func,
};

export default Footer;