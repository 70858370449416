import React from 'react'
import PropTypes from 'prop-types'

import { offerType } from 'utils/offersFormHelper';

import { DialogTitle } from '@mui/material';
import { t } from 'i18n/index';

const Title = ({resetToken, preResetDescription, offer, logIn}) => {

  const internalOffer = offer && offer.offer_type === offerType.internal;
  const externalOffer = offer && offer.offer_type === offerType.external;

  const titles = { resetToken : preResetDescription ? t('authModal:getStarted') : t('authModal:welcome'),
                   other: logIn ? t('authModal:welcomeBack') : t('authModal:getStarted') }

  const titleKey = resetToken ? 'resetToken' : 'other';

  const descriptions = {
    resetToken : preResetDescription ? t('authModal:resetDescription') : "",
    internalOffer : t('authModal:internalOfferDescription'),
    externalOffer: t('authModal:externalOfferDescription'),
    nft: t('authModal:nftDescription')
  }

  const descriptionKey = resetToken ? 'resetToken' : internalOffer ? 'internalOffer' : externalOffer ? 'externalOffer' : 'nft';

  return (
    <DialogTitle>
        <h1>{titles[titleKey]}</h1>
        <p>{descriptions[descriptionKey]}</p>
    </DialogTitle>
  )
};

Title.propTypes = {
    resetToken: PropTypes.string,
    preResetDescription: PropTypes.bool,
    offer: PropTypes.object,
    logIn: PropTypes.bool,
};

export default Title;