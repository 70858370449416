import React from 'react'
import PropTypes from 'prop-types'
import RedeemLimit from './RedeemLimit';
import EndDate from './EndDate';

const index = ({ offer, onOfferChange, isDisabled, }) => {

    const handleOfferChange = ( { target: {name, value}} ) => {
        onOfferChange({
            ...offer,
            [name]: value
        });
    };  

    const handleDateTimeChange = (newValue) => {
        let newOffer = {...offer};
        newOffer['end_date'] = newValue;
        onOfferChange(newOffer);
    };

    return (
        <div>
            <h3 className={`new-form-section`}>Link Settings</h3>

            <RedeemLimit offer={offer} 
                            onChange={event => handleOfferChange(event)}
                            disabled={isDisabled} />

            <EndDate offer={offer} 
                        onChange={handleDateTimeChange} />
        </div>
    )
}

index.propTypes = {
    offer: PropTypes.object,
    onOfferChange: PropTypes.func,
    isDisabled: PropTypes.bool,
}

export default index;