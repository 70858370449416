import React from 'react';
import PropTypes from 'prop-types'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { t } from 'i18n/index';

const ConfirmDialog = ({ icon, title, children, open, setOpen, onConfirm, mysteryBox, handleClose }) => {
  
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        if (handleClose) {
          handleClose();
        }
      }}
      aria-labelledby="confirm-dialog"
    >  
      {icon ?
        <div className='confirm-modal-header'>
          {icon}
          <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        </div>
        : 
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      }
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            handleClose();
          }}
          color="secondary"
        >
          {mysteryBox ? t('publishModal:no') : t('globalVariables:no')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="error"
        >
          {mysteryBox ? t('publishModal:yes') : t('globalVariables:yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  mysteryBox: PropTypes.object,
  handleClose: PropTypes.func
}

export default ConfirmDialog;