import React, {useEffect, useState} from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import EmailResource from 'resources/user/EmailResource';

import StatusModal from 'components/StatusModal';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { t } from 'i18n/index';
import CountrySelector from 'components/CountrySelector';

const InvoiceForm = ({ handlePaymentSelector, invoiceData, handleInvoiceData, gift, senderName, senderEmail, loggedIn, handleLogin}) => {

    const { fetch } = useController();

    useEffect(() => {
        handleInvoiceData( {...invoiceData, ["name"]: senderName, ["email"]: senderEmail });  
    }, []);
 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const handleChange = ({ target: {name, value}}) => {
        handleInvoiceData( {
            ...invoiceData,
            [name]: value
        });
    };   

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        setLoading(false);
    };

    const handleValidationError = (message) => {
        setError(message);
        handleOpenErrorModal();
        throw ''
    };

    const handleValidation = (invoiceData) => {
        setLoading(true);
        if(invoiceData.name === undefined || invoiceData.name === "" ) {
            handleValidationError(t('invoiceForm:nameMissing'));
        }
        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if((!invoiceData.email || !invoiceData.email.match(validEmail))) {
            handleValidationError(t('invoiceForm:emailMissing'));
        }
        if (!invoiceData.country){
            handleValidationError(t('invoiceForm:countryMissing'));
        }
        setLoading(false);
        return true;
    };

    const handleGiftSubmission = async (e, invoiceData) => {
        e.preventDefault();
        handleValidation(invoiceData);
        if ( !loggedIn ) {
            handlePaymentSelector();
        } else {
            try {
                const { user_found } = await fetch(EmailResource.list(), { email: invoiceData.email } );
                if ( !user_found ) {
                    handlePaymentSelector();
                } else {
                    setError(t('invoiceForm:userExists'));
                    handleOpenErrorModal();
                }
            } catch ( error ) {
                handleValidationError(t('invoiceForm:errorValidatingEmail'));
            }
        }
    };

    return (
        <>
            <form onSubmit={ (e) => handleGiftSubmission(e, invoiceData) } >
                <>
                <h3 className="new-form-section">{t('invoiceForm:personalDetails')}:</h3>
                <TextField label={t('invoiceForm:senderName')} 
                            name="name"
                            value={ invoiceData.name }
                            onChange={ handleChange }
                            fullWidth 
                            margin="dense" 
                            color="warning"
                            disabled={ senderName }>
                </TextField>
                <TextField label={t('invoiceForm:vat')} 
                            name="vat"
                            value={ invoiceData.vat || '' }
                            onChange={ handleChange }
                            fullWidth 
                            margin="dense" 
                            color="warning">
                </TextField>
                {!gift &&
                    <>
                        <h3 className="new-form-section">{t('invoiceForm:contact')}:</h3>
                        <TextField label={t('invoiceForm:senderEmail')} 
                                    name="email"
                                    value={ invoiceData.email }
                                    onChange={ handleChange }
                                    fullWidth 
                                    margin="dense" 
                                    color="warning"
                                    disabled={ senderEmail }>
                        </TextField>
                    </>
                }
                <h3 className="new-form-section">{t('invoiceForm:address')}:</h3>
                <TextField label={t('invoiceForm:streetAddress')} 
                            name="address"
                            value={ invoiceData.address || '' }
                            onChange={ handleChange }
                            fullWidth 
                            margin="dense" 
                            color="warning">
                </TextField>
                <div className='invoice-form-row-container'>
                    <TextField label={t('invoiceForm:postCode')} 
                                name="post_code"
                                value={ invoiceData.post_code || '' }
                                onChange={ handleChange }
                                fullWidth 
                                margin="dense" 
                                color="warning">
                    </TextField>
                    <TextField label={t('invoiceForm:city')} 
                                name="city"
                                value={ invoiceData.city || '' }
                                onChange={ handleChange }
                                fullWidth 
                                margin="dense" 
                                color="warning">
                    </TextField>
                </div>
                
                <CountrySelector data={invoiceData} onDataChange={handleInvoiceData}  />

                <Button fullWidth
                        variant="contained"
                        color="primary"
                        type="submit">
                    {loading
                    ? `Sending...`
                    : t('invoiceForm:proceed')
                    }
                </Button>
                </>
            </form>

            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal } handleLogin={ handleLogin }/>}

        </>
    )
};

InvoiceForm.propTypes = {
  done: PropTypes.func,
  handlePaymentSelector: PropTypes.func,
  invoiceData: PropTypes.object,
  handleInvoiceData: PropTypes.func,
  gift: PropTypes.bool,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
  loggedIn: PropTypes.bool,
  handleLogin: PropTypes.func,
};

export default InvoiceForm;
