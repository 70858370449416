import moment from 'moment';

export const placeholders = {
  name : '<Participant Name>',
  date : `<${moment().format('DD/MMM/YYYY')}>`,
  serial: '<00001>'
}

export const dynamicVars = [
    {
      value: 'name',
      label: 'Participant Name',
    },
    {
      value: 'date',
      label: 'Claim Date',
    },
    {
      value: 'serial_number',
      label: 'Serial Number',
    },
  ];

