import React from 'react';

import { t } from "i18n/index";

const GiftSent = () => {

  return (
    <div>
        <h4>{t('checkoutForm:thankYouForPayment')}</h4>
        <p>{t('checkoutForm:giftBeingSent')}</p>
    </div> 
  )
}

export default GiftSent