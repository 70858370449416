import React from 'react';
import PropTypes from 'prop-types';
import CourseSwitcher from './CourseSwitcher';

const PageTop = ({ pageInfo, cta, actionsMenu=null, statOverview=null, course}) => (

    <div className="page-top grid">
      { pageInfo && pageInfo.photo ? 
        <div className="page-profile-pic">
          <div className="page-profile-pic-wrapper">
            <img src={ pageInfo.photo } />
          </div>
        </div>
        : ''
      }

      { pageInfo &&

        <div className="page-info">
          <div>
            <div className="page-label">{ pageInfo["label"] }</div>
            <div className="page-name">{ pageInfo["name"] }</div>
          </div>

          { pageInfo["description"] &&
            <div className="page-description">{ pageInfo["description"] }</div>
          }

          { pageInfo["action"] && cta }
        </div>
      }

      <div className="page-actions">
        { actionsMenu }
      </div>

      <div className="page-stat">
        { statOverview }
      </div>
      
      {course && 
        <CourseSwitcher course={course} />
      }
      
    </div>



);

PageTop.propTypes = {
  pageInfo: PropTypes.object,
  cta: PropTypes.node,
  actionsMenu: PropTypes.node,
  statOverview: PropTypes.node,
  course: PropTypes.object
};

export default PageTop;