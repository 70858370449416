import React, {useState, useCallback} from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import RegistrationResource from 'resources/auth/RegistrationResource';
import ClaimResource from 'resources/public/ClaimResource';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import StatusModal from 'components/StatusModal';
import AuthToken    from 'utils/localStorage/AuthToken';
import { TCLabel } from 'utils/TCLabel';
import CoursesList    from 'utils/localStorage/CoursesList';
import UserName    from 'utils/localStorage/UserName';
import LastView    from 'utils/localStorage/LastView';

import ConnectWalletButton from './WalletConnect/ConnectWalletButton';

import { t } from 'i18n/index';

const SignUpForm = ({ done, sellable, handleSuccessfulAuth, tokenId, hideEmailSignUp, hideWalletConnect}) => {

  const { fetch } = useController();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const handleChange = ({ target: {name, value}}) => {
    setData( {
      ...data,
      [name]: value
    });
  };

  const handleCheckBox = (event) => {
    setData( {
      ...data,
      agreement: event.target.checked
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  const handleValidation = (data) => {
    setLoading(true);
    if(data.name === undefined ) {
      setError(t('singUpForm:nameMissing'));
      handleOpenErrorModal();
      throw ''
    }
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (data.email === undefined || !data.email.match(validEmail)){
      setError(t('singUpForm:emailMissing'));
      handleOpenErrorModal();
      throw '';
    }
    if (data.password === undefined) {
      setError(t('singUpForm:passwordMissing'));
      handleOpenErrorModal();
      throw '';
    }
    if (data.agreement !== true){
      setError(t('singUpForm:dataAgreementMissing'));
      handleOpenErrorModal();
      throw '';
    }
    setLoading(false);
    return true;
  };

  const handleWalletConnect = ({ wallet_address, challenge, signature }) => {
    const data = new FormData();
    data.append('wallet_address', wallet_address);
    data.append('challenge', challenge);
    data.append('signature', signature);
    data.append('challenge_type', 'signup');
    handleSignUp(data);
  };

  const handleEmailSignup = (e, data) => {
    e.preventDefault();
    handleValidation(data);
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) => formData.append(name, value));
    handleSignUp(formData);
  };

  const handleSignUp = useCallback(
    async (data) => {
      try {
          const { auth_token, user } = await fetch(RegistrationResource.create(), data);
          if (auth_token){
            AuthToken.write(auth_token);
            CoursesList.write([]);
            UserName.write(user.name);
            LastView.write("student");

            if (tokenId){
              await fetch(ClaimResource.detail(), {id: tokenId});
            }

            if (done){
              if (sellable){
                handleSuccessfulAuth();
              } else {
                done();
              }
            }
          }
        // success!
      }  catch (error) {
        const data = await error.response.json();
        setError(data.errors.base[0]);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <div>
      { !hideEmailSignUp &&
        <div>
          <form onSubmit={ (e) => handleEmailSignup(e, data) } >
            <TextField label={t('signUpPage:signUpModal_Name')} name="name"
                        value={ data.name || '' }
                        onChange={ handleChange }
                        fullWidth margin="normal" color="warning"></TextField>
            <TextField label="Email" name="email"
                        value={ data.email || '' }
                        onChange={ handleChange }
                        fullWidth margin="normal" color="warning"></TextField>
            <TextField label={t('loginPage:login_Password')} name="password" type={'password'}
                        value={ data.password || '' }
                        onChange={ handleChange }
                        fullWidth margin="normal" color="warning"></TextField>
            <FormControlLabel
              control={
                <Checkbox checked={ data.agreement || false}
                          onChange={handleCheckBox}
                          label={t('signUpPage:signUpModal_AgreeTerms')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={TCLabel}
            />
            <Button fullWidth
                    variant="contained"
                    color="primary"
                    type="submit">
              {loading
              ? `Sending...`
              : t('signUpPage:signUpModal_Continue')
              }
            </Button>
          </form>
          <Divider sx={{my: '20px'}} > OR </Divider>
        </div>
        }
        
      { !hideWalletConnect && <ConnectWalletButton challengeType={ 'signup' } done={ handleWalletConnect } />}

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </div>
  )
};

SignUpForm.propTypes = {
  done: PropTypes.func,
  sellable: PropTypes.bool,
  handleSuccessfulAuth: PropTypes.func,
  tokenId: PropTypes.string,
  hideEmailSignUp: PropTypes.bool,
  hideWalletConnect: PropTypes.bool,
};

export default SignUpForm;
