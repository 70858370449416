import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Description from './Description';

const GeneralSettings = ({ offer, onOfferChange, isDisabled, }) => {

  const handleOfferChange = ( { target: {name, value}} ) => {
        onOfferChange({
            ...offer,
            [name]: value
        });
    };  

  return (
    <div>
      <Title offer={offer} 
              onChange={event => handleOfferChange(event)}
              disabled={isDisabled} />

      <Description 
              offer={offer} 
              onChange={event => handleOfferChange(event)}
              disabled={isDisabled} />
    </div>
  )
}

GeneralSettings.propTypes = { 
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default GeneralSettings;