import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NewCourseForm from './NewCourseForm'
import NewOrganizationForm from './NewOrganizationForm'

import handleRoyalties from 'utils/handleRoyalties';
import { courseType } from 'utils/constants';

import Button from '@mui/material/Button';

const emptyCourse = { title: '', description: '', category: '', external_link: '' };
const emptyOrganization = { organization_title: '', organization_category: '', organization_admin_role: '', organization_external_link: '' };
const organizationGoals = [ "Create NFTs for my events & experiences",
                            "Issue Digital Certificates",
                            "Create Utility for my NFTs",
                            "Other" ]
  
const CourseForm = ({record={}, organizationId, onSubmit, permissions }) => {
  const [course, setCourse] = useState({...emptyCourse, organization_id: organizationId, ...record });
  const [organization, setOrganization] = useState({...emptyOrganization });

  const handleCourseChange = (event) => {
    const {name, value} = event.target;
    let newCourse = {...course};
    newCourse[name] = value;
    setCourse(newCourse);
  };

  const sellableCollectibles =  course.category === "sellableCollectibles" || organization.course_category === "sellableCollectibles";
  
  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  const royalties = handleRoyalties(course.royalties);
  
  const transformedRoyalties = course.royalties && royalties !== course.royalties;

  return (
    <form id="admin-courses-add" onSubmit={ (e) => onSubmit(e, course, organization) } >
          
      {!organizationId &&
        <NewOrganizationForm organization={organization}
                             onOrganizationChange={ setOrganization }
                             organizationGoals={organizationGoals}
        />
      }
      
      <NewCourseForm organizationId={organizationId}
                     course={course}
                     handleCourseChange={event => handleCourseChange(event)}
                     permissions={permissions}
                     sellableCollectibles={sellableCollectibles}
                     transformedRoyalties={transformedRoyalties}
                     mysteryBox={mysteryBox}
      />  

      <div className={ `new-form-field` }>
        <Button variant="contained" color="primary" type="submit">
        { !record.id ? 'Create' : 'Save' }
        </Button>
      </div>

    </form>
  )
};

CourseForm.propTypes = {
  record: PropTypes.object,
  organizationId: PropTypes.number,
  onSubmit: PropTypes.func,
  permissions: PropTypes.array,
};

export default CourseForm;