import React from 'react';
import PropTypes from 'prop-types';

import { Stepper, Step, StepLabel } from '@mui/material';

const DesktopStepper = ({activeStep, steps }) => {

  return (
    <Stepper activeStep={activeStep} alternativeLabel className='desktop-checkout-stepper hidden'>
        {steps.map((label) => (
          <Step key={label}
            sx={{
            '& .MuiStepLabel-root .Mui-completed': {
                color: '#F75201',
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                color: 'black',
                },
            '& .MuiStepLabel-root .Mui-active': {
                color: '#F75201',
            },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: 'white',
            },
            }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
    </Stepper>
  )
}

DesktopStepper.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.array,
}

export default DesktopStepper