import React, { useState } from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import StatusModal from 'components/StatusModal';

import OfferInstanceResource from 'resources/user/OfferInstanceResource';
import OfferResource from 'resources/user/OfferResource';

import PreRedeemContent from './PreRedeemContent';
import PostRedeemContent from './PostRedeemContent';

import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';

import { t } from 'i18n/index';

const RedeemModal = ({ title, open, onClose, course, offerId, reward, internal, onRedeem }) => {
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [redeemedContent, setRedeemedContent] = useState({});

    const { setOpen, setMessage, setSeverity } = useToast();
    const { fetch } = useController();

    const handleInternalRedeem = () => {
        const data = new FormData();
        data.append('offer_id', offerId);
        data.append('reward_instance_id', reward.id);
        handleRedeem(data);
    };

    const handleExternalRedeem = ({ wallet_address, challenge, signature }) => {
        const data = new FormData();
        data.append('offer_id', offerId);
        data.append('wallet_address', wallet_address);
        data.append('challenge', challenge);
        data.append('signature', signature);
        handleRedeem(data);
    };

    const handleRedeem = async (data) => {
        // TODO: fix this validation
        // if ( (reward && reward.claim_state !== 'onchain') || !internal){
        if (internal || !internal){
            try {
                const offerInstance = await fetch(OfferInstanceResource.create(), data);
                const { id } = offerInstance;
                // success!
                if (id){
                    if (offerInstance.content_text) {
                        setRedeemedContent(offerInstance);
                    } else {
                        fetch(OfferInstanceResource.list());
                        const updatedOffers = await fetch(OfferResource.list());
                        onRedeem(updatedOffers);
                        customToast('success', t('redeemModal:redeemSuccess'), setOpen, setSeverity, setMessage);
                        onClose();
                    }
                }

            }  catch (error) {
                const data = await error.response.json();
                setError(data.errors.base[0]);
                handleOpenErrorModal();
            }
        } else {
            setError(t('redeemModal:onChainValidationError'));
            setOpenErrorModal(true);
        }
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        handleClose();
    };

    const handleClose = async () => {
        if (redeemedContent.content_text){
            await fetch(OfferInstanceResource.list());
            const updatedOffers = await fetch(OfferResource.list());
            onRedeem(updatedOffers);
            setRedeemedContent({});
            onClose();
        } else {
            onClose();
        }
    };

    return (
    <div>
        <Dialog open={ open } onClose={ handleClose } >
            <DialogTitle>{redeemedContent.content_text ?
                            `${t('offersLabels:offerRedeemedLabel')}: ${title}`
                          :
                            `${t('offersLabels:redeem')} ${title} ?`
                          }
            </DialogTitle>
            {!redeemedContent.content_text ?
                <PreRedeemContent course={ course }
                                  title={ title }
                                  onClose={ onClose }
                                  internal={ internal }
                                  onInternalRedeem={ handleInternalRedeem }
                                  onExternalRedeem={ handleExternalRedeem }
                />
            :
                <PostRedeemContent redeemedContent={ redeemedContent }
                                   token={ reward }
                />
            }
        </Dialog>

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
    </div>
    );
};

RedeemModal.propTypes = {
    title: PropTypes.string,
    course: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    offerId: PropTypes.number,
    reward: PropTypes.object,
    internal: PropTypes.bool,
    onRedeem: PropTypes.func,
};

export default RedeemModal;
