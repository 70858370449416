import moment               from 'moment';

const dateFormats = {
    "datetime_long":  "MM/DD/YY hh:mm A z",
    "date":           "MM/DD/YYYY",
    "short_date":     "MM/DD/YY",
    "month_year":     "MM YYYY",
    "alpha_date":     "MMMM D, YYYY",
    "alpha_month_year": "MMMM YYYY",
    "quarter":        "[Q]Q YYYY",
    "year":           "YYYY",
    "time":           "hh:mm A",
    "hour":           "hh A"
};

const formattedDate = (date, formatName='datetime_long', fallback='Never') => {
  const format = dateFormats[formatName];
  return date ? moment(date).format(format) : fallback;
};


export default formattedDate;
