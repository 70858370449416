import React from 'react'
import PropTypes from 'prop-types'
import { useController } from '@rest-hooks/react';
import { useHistory } from 'react-router-dom';

import { findRewardInstance } from 'utils/claimActions/payments/findRewardInstance';

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { t } from 'i18n/index';


const StripePaymentSubForm = ({ gift, 
                                finalPrice,
                                currencySymbol,
                                handleStep,
                                handleMessage,
                                handleErrorFindingReward,
                            }) => {

    const paymentElementOptions = {
        layout: "tabs"
    };

    const stripe = useStripe();
    const elements = useElements();

    const { fetch } = useController();
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
            },
            redirect: 'if_required'
        });

        if (!response.error) {
            if (!gift) { 
            handleStep(4);
            const paymentIntentId = response.paymentIntent.id;
            findRewardInstance(paymentIntentId, 6, 3000, fetch, history, handleErrorFindingReward);
            } else {
            handleStep(5);
            }             
        } else if (response.error.type === "card_error" || response.error.type === "validation_error") {
            handleMessage(response.error.message);
        } else {
            handleMessage(t('checkoutForm:paymentFailedDefault'));
        }   
    };
      
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={!stripe || !elements} id="submit" className="payment-button">
        <span id="button-text">
            {t('checkoutForm:pay', { finalPrice, currencySymbol })}
        </span>
        </button>
    </form>
  )
}

StripePaymentSubForm.propTypes = {
    gift: PropTypes.bool,
    finalPrice: PropTypes.string,
    currencySymbol: PropTypes.string,
    handleStep: PropTypes.func,
    handleErrorFindingReward: PropTypes.func,
    handleMessage: PropTypes.func,
}

export default StripePaymentSubForm