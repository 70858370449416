export const revealSupplyLimitCheck = (data, ids) => {
  
  const supplyLimits = data.reduce((limits, item) => {
    limits[item.id] = item.supply_limit;
    return limits;
  }, {});

  const supplyLimitsArray = ids.map(id => supplyLimits[id]);

  return supplyLimitsArray.every(limit => limit === supplyLimitsArray[0]);
};