import React from 'react'
import PropTypes from 'prop-types'

import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { t } from 'i18n/index';

const Filter = ({ label, items, value, handleFilter, rewardId, collectionId }) => {
  return (
    <FormControl sx={{ width: 275 }} size="small">
        <InputLabel color="warning">{t('globalVariables:filterBy')} {label}</InputLabel>
        <Select size="small" label={`${t('globalVariables:filterBy')} ${label}`} color="warning" value={value}>
          {items.map((item) => (
            <MenuItem key={item.id} 
                      value={item.id} 
                      onClick={() => {
                        label === "Organization" ? handleFilter( item.id )
                          : label === "User" ? handleFilter( undefined, item.user_id )
                            : label === "Collection" ? handleFilter( item.id, rewardId )
                              : handleFilter( collectionId, item.id )
                      }}>
              {label === "User" ? item.email : item.title}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem key="show-all" onClick={() => {
                                      label === "Collectible" ? handleFilter(collectionId, null)
                                        : label === "Collection" ? handleFilter(null, rewardId) 
                                          : handleFilter(null)}
                                   }>
            <RemoveRedEyeIcon sx={{ marginRight: 2 }} /> {t('globalVariables:showAll')}
          </MenuItem>
        </Select>
      </FormControl>
  )
}

Filter.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    value: PropTypes.string,
    handleFilter: PropTypes.func,
    rewardId: PropTypes.number,
    collectionId: PropTypes.number,
}

export default Filter