import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/admin/OrganizationResource';
import Form from './form';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import Dialog from '@mui/material/Dialog';
import { useController } from '@rest-hooks/react';

const EditOrganizationModal = ({ open, onClose, handleError, activeOrg, handleActiveOrg }) => {

  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();

  const handleChange = (event) => {
    const { name, checked, value } = event.target;
    if (name === "title"){
      handleActiveOrg({ ...activeOrg, [name]: value })
    } else {
      if (checked) {
        handleActiveOrg({ ...activeOrg, [name]: true });
      } else {
        handleActiveOrg({ ...activeOrg, [name]: false });
      }
    }
  };
  
  const handleValidation = (formData) => {
    if (formData.get("title") === ""){
      handleError("Please tell us your organization name.");
    }
  };

  const createFormData = (org) => {
    const formData = new FormData();
    const fields = [
      {key: "title", value: org.title},
    ]

    if (org.hasMinting) fields.push({key: "modules[]", value: "minting"});
    if (org.hasOffers) fields.push({key: "modules[]", value: "offers"});
    if (org.hasSales) fields.push({key: "modules[]", value: "sales"});
    if (org.hasWhitelabel) fields.push({key: "modules[]", value: "whitelabel"});
    if (org.hasMysterybox) fields.push({key: "modules[]", value: "mysterybox"});

    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  }

  const handleEditOrganization = useCallback(
    async (e, org) => {
      e.preventDefault();
      const formData = createFormData(org);
      handleValidation(formData);
      try {
        const {id, title} = await fetch(OrganizationResource.update(), {id: org.id}, formData);
  
        //success!
        if (id){
          fetch(OrganizationResource.list());
          customToast('success', `Organization ${title} updated`, setOpen, setSeverity, setMessage);
          onClose();
        }
      }  catch (error) {
        handleError(error.message)
      }
    },
    [fetch],
  );

  return (
    <Dialog open={ open } 
            onClose={ onClose }>

      <Form activeOrg={ activeOrg } 
            onSubmit={ handleEditOrganization } 
            onChange={ handleChange }  
            onClose={ onClose }
            />

    </Dialog>
  );
}

EditOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleError: PropTypes.func,
  activeOrg: PropTypes.object,
  handleActiveOrg: PropTypes.func,
};

export default EditOrganizationModal;