import React from "react";
import PropTypes from "prop-types";

import LastCourse from "utils/localStorage/LastCourse";
import CoursesList from "utils/localStorage/CoursesList";
import { courseType } from 'utils/constants';

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import ActionMenu from "containers/teacher/Dashboard/ActionsMenu";

const CourseSwitcher = ({ course }) => {
  const params = useParams();
  const history = useHistory();

  const organizationId = parseInt(params.organizationId);
  const coursesList = CoursesList.read();
  const organizationCoursesList = coursesList.filter(
    (course) => course.organization_id === parseInt(organizationId)
  );

  const courseCategory = Object.values(courseType).find((type) => type.value === course.category)?.label;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    if (event.target.value === undefined) {
      setAnchorEl(null);
    } else {
      const lastCoursesList = LastCourse.read();

      const orgIndex = lastCoursesList.findIndex((element) => Object.prototype.hasOwnProperty.call(element, organizationId));
      if (orgIndex !== -1) {
        lastCoursesList.splice(orgIndex, 1);
      }

      lastCoursesList.unshift({ [organizationId]: event.target.value });
      LastCourse.write(lastCoursesList);
      
      history.push(`/organizations/${organizationId}/collections/${event.target.value}`);
      setAnchorEl(null);
    }
  };

  return (
    <div className="course-switcher-main-container">
      <p>Current Collection:</p>
      <div className="course-switcher-inner-container">
        <h2>{course.title}</h2>
        <div>
          
          <IconButton className="orange" disableElevation onClick={handleClick}>
            {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: "60vh",
                minWidth: "225px"
              },
            }}
          >
            {organizationCoursesList.map(({ id, title }) => (
              <MenuItem
                className={"MuiMenu-item"}
                key={id}
                value={id}
                onClick={handleClose}
              >
                {title}
              </MenuItem>
            ))}

            <Divider sx={{ my: 0.5 }} />

            <MenuItem>
              <Link
                className="menu-link"
                to={`/organizations/${organizationId}/collections/new`}
              >
                <>Add New Collection</>
                <ControlPointIcon />
              </Link>
            </MenuItem>
          </Menu>

          <ActionMenu
            id={course.id}
            category={course.category}
            invite={course.invite}
            organizationId={course.organization_id}
          />
        </div>
      </div>        
      <p>Type: {courseCategory}</p>
    </div>
  );
};

CourseSwitcher.propTypes = {
  course: PropTypes.object,
};

export default CourseSwitcher;
