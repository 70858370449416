import React, { useState, useCallback } from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import AuthenticationResource from 'resources/auth/AuthenticationResource';
import ClaimResource from 'resources/public/ClaimResource';
import StatusModal from 'components/StatusModal';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import UserName from 'utils/localStorage/UserName';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';

import { t } from 'i18n/index';
import redirectToView from 'utils/redirectToView';

import ConnectWalletButton from './WalletConnect/ConnectWalletButton';

const LogInForm = ({ buttonText=t('loginPage:loginModal_LogMeIn'), done, sellable, handleSuccessfulAuth, tokenId }) => {
  const { fetch } = useController();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleValidation = (e) => {
    if (e.target.email.value === ""){
      setError("Please provide a valid email.");
      handleOpenErrorModal();
      throw '';
    }
    if (e.target.password.value === ""){
      setError("Please write your password.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleWalletConnect = ({ wallet_address, challenge, signature }) => {
    const data = new FormData();
    data.append('wallet_address', wallet_address);
    data.append('challenge', challenge);
    data.append('signature', signature);
    data.append('challenge_type', 'login');
    handleLogin(data);
  };

  const handleEmailLogin = (e) => {
    e.preventDefault();
    handleValidation(e);
    const data = new FormData(e.target);
    handleLogin(data);
  }

  const handleLogin = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const { auth_token, user, courses } = await fetch(AuthenticationResource.create(), data );

        // success!
        if (auth_token){
          AuthToken.write(auth_token);
          const { role, name } = user;
          CoursesList.write(courses);
          UserName.write(name);

          if (tokenId){
            await fetch(ClaimResource.detail(), {id: tokenId})
          }

          if (done){
            if (sellable) {
              handleSuccessfulAuth();
            } else {
              done();
            }
          } else {

            redirectToView(history, role, courses);

          }
        }
      } catch (error) {
        if (error.status === 404){
          setError("Invalid Credentials");
          handleOpenErrorModal();
        } else {
          setError(error.message);
          handleOpenErrorModal();
        }
      }
    },
    [fetch],
  );

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  return (
      <>
        <form onSubmit={ handleEmailLogin } >
          <TextField label="Email" name="email" color="warning" fullWidth margin="normal"></TextField>
          <TextField label={t('loginPage:login_Password')} name="password" type={'password'} color="warning" fullWidth margin="normal"></TextField>
          <p className="no-account-modal">{t('loginPage:login_ForgotPassword')}<a href="/password_resets" target="_blank">&nbsp;{t('loginPage:login_Reset')}</a></p>

          <Button type='submit'
                  fullWidth
                  variant="contained"
                  color="primary">
            {loading ?
            "Sending" :
            buttonText}
          </Button>

          <Divider sx={{my: '20px'}} > OR </Divider>

          <ConnectWalletButton challengeType={ 'login' } done={ handleWalletConnect } />

        </form>

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
      </>
    )
}

LogInForm.propTypes = {
  buttonText: PropTypes.string,
  done: PropTypes.func,
  sellable: PropTypes.bool,
  handleSuccessfulAuth: PropTypes.func,
  tokenId: PropTypes.number,
};

export default LogInForm;
