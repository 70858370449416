import AuthenticatedResource from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class SearchOffersResource extends AuthenticatedResource {
  static urlRoot = '/search_offers'
  
  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

}