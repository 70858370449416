import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

const EditForm = ({activeOrg, onSubmit, onChange, onClose}) => {
    
  const modules = Object.keys(activeOrg).filter((module) =>
    module.startsWith('has')
  );

  return (
    <form onSubmit={ e => onSubmit(e, activeOrg) } >
        <DialogTitle>Edit {activeOrg.name}</DialogTitle>
        <DialogContent dividers>
        
          <TextField
            margin="dense"
            name="title"
            label="Change Name to *"
            fullWidth
            color="warning"
            value={activeOrg.title}
            onChange={ event => onChange(event) }
          />

        <div>
        {modules.map((moduleName) => (
            <FormControlLabel
              key={moduleName}
              control={
                <Checkbox
                  checked={activeOrg[moduleName]}
                  onChange={onChange}
                  name={moduleName}
                  color="primary"
                />
              }
              label={moduleName.slice(3)}
            />
          ))}
        </div>

        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={ onClose }>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Save Changes</Button>
        </DialogActions>
      </form>
  )
};

EditForm.propTypes = {
    activeOrg: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
};

export default EditForm;