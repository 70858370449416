import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react'
import logo from '../assets/images/logo.png'

 const QrCode = ({url}) => {
  return (
    <QRCode id='qrCodeId'
            size={400}
            value={url}   
            bgColor='white'
            fgColor='black'
            level='Q'
            includeMargin={true}
            imageSettings={{
              src: logo,
              excavate: true,
              width: 100,
              height: 100,
              padding: 10,
            }}
    />
  )
};
    
QrCode.propTypes = {
    url: PropTypes.string,
}

export default QrCode;