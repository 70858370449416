import React from 'react'
import PropTypes from 'prop-types'

import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { t } from 'i18n/index';

const PreRedeemContent = ({course, title, onClose, internal, onInternalRedeem, onExternalRedeem}) => {
    return (
        <div>
            <DialogContent dividers >
                <DialogContentText>
                    <p>{course ? t('redeemModal:firstLabel', {title, course}) : t('redeemModal:firstLabelExternal', {title}) }</p>
                    <p>{t('redeemModal:secondLabel')}</p>
                    <p>{t('redeemModal:thirdLabel')}</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ marginRight: '10px' }}
                        variant="outlined"
                        color="primary"
                        onClick={ onClose }>
                    {t('redeemModal:redeemButtomCancel')}
                </Button>

                { internal ?
                    <Button variant="contained"
                            color="warning"
                            classes='MuiButton-outlinedWarning'
                            onClick={ onInternalRedeem }>
                        {t('redeemModal:redeemButtomConfirm')}
                    </Button>
                    :
                    <ConnectWalletButton challengeType='redeem' done={ onExternalRedeem } / >
                }
            </DialogActions>
        </div>
    );
};

PreRedeemContent.propTypes = {
    course: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
    internal: PropTypes.bool,
    onInternalRedeem: PropTypes.func,
    onExternalRedeem: PropTypes.func,
};

export default PreRedeemContent;