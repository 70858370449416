import React from 'react';
import PropTypes from 'prop-types';

import { t } from 'i18n/index';

const ClaimSettings = ({supply_limit, end_date}) => {
  return (
    <div className='center claim-settings-container'>
        
        {supply_limit && <p><span className='bold'>{t('globalVariables:limitedSupply')}:</span> {supply_limit}</p>}
        {end_date && <p><span className='bold'>{t('globalVariables:expirationDate')}:</span> {end_date}</p>}

    </div>
  )
}

ClaimSettings.propTypes = {
    supply_limit: PropTypes.number,
    end_date: PropTypes.string,
};


export default ClaimSettings