import React from 'react';
import PropTypes from 'prop-types';

import InvoiceForm from './InvoiceForm';

import { t } from 'i18n/index';

const InvoiceDetails = ({handlePaymentSelector, invoiceData, handleInvoiceData, gift, senderName, senderEmail, loggedIn, handleLogin}) => {

  return (
    <div>
      <h1>{t('invoiceForm:title')}</h1>
      <p>{t('invoiceForm:message')}</p>

      <InvoiceForm 
        handlePaymentSelector={ handlePaymentSelector }
        invoiceData={ invoiceData }
        handleInvoiceData={ handleInvoiceData} 
        gift={ gift }
        senderName={ senderName }
        senderEmail={ senderEmail } 
        loggedIn={ loggedIn }
        handleLogin={ handleLogin }
      /> 
    </div>
  );
}

InvoiceDetails.propTypes = {
  handlePaymentSelector: PropTypes.func,
  invoiceData: PropTypes.object,
  handleInvoiceData: PropTypes.func,
  gift: PropTypes.bool,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
  loggedIn: PropTypes.bool,
  handleLogin: PropTypes.func,
};

export default InvoiceDetails;
