import React from 'react'

const Sales = () => {
  return (
    <div>
        <p>
          Your current plan does not support selling Digital Collectibles and NFTs via Crowdclass.
        </p>
        <h4>
          Reach out via e-mail to <span className="italized">hello@crowdclass.com</span> or via our chat to discuss with our team.
        </h4>
    </div>
  )
}

export default Sales