import React from 'react';
import PropTypes from 'prop-types';

import GiftForm from './GiftForm';

import { t } from 'i18n/index';

const GiftDetails = ({handleInvoiceDetails, 
                      giftData,
                      handleGiftData,
                      senderName,
                      senderEmail
                    }) => {


  return (
    <div>
      <h1>{t('giftModal:title')}</h1>
      <p>{t('giftModal:message')}</p>

      <GiftForm 
        handleInvoiceDetails={ handleInvoiceDetails }
        giftData={giftData}
        handleGiftData={handleGiftData} 
        senderName={senderName}
        senderEmail={senderEmail} 
      /> 
    </div>
  );
}

GiftDetails.propTypes = {
  handleInvoiceDetails: PropTypes.func,
  giftData: PropTypes.object,
  handleGiftData: PropTypes.func,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
};

export default GiftDetails;
