export const membersColumns = [
    {
      field: 'fullName',
      name: 'Full name',
      sortable: true,
      width: 200,
      selector: (row) => `${row.name || ''}`
    },
    { 
      field: 'email',
      name: 'Email Address',
      sortable: true,
      width: 210,
      selector : (row) => row.email
    }
  ];