import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import LastView from 'utils/localStorage/LastView';
import LastCourse from 'utils/localStorage/LastCourse';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';

const SwitcherMenu = ({ context, userName, coursesList, handleLogout, organizationsList}) => {
    
    const history = useHistory();
    const params = useParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const organizationId = params.organizationId ? parseInt(params.organizationId) : null;

    const handleOrganizationChange = async (id) => {

        const activeOrganization = organizationsList.filter((element) => element.id === id);
        const lastCourseList = LastCourse.read() || [];
        const orgIndex = lastCourseList.findIndex((element) => Object.prototype.hasOwnProperty.call(element, id));

        if (orgIndex !== -1) {
            LastView.write('teacher');
            const courseId = lastCourseList[orgIndex][id];
            lastCourseList.splice(orgIndex, 1);
            lastCourseList.unshift({ [id]: courseId });
            LastCourse.write(lastCourseList);
            history.push(`/organizations/${id}/collections/${courseId}`);

        } else {
            LastView.write('teacher')
            const firstCourseId = activeOrganization[0].courses[0].id;
            lastCourseList.unshift({[id]: firstCourseId});
            LastCourse.write(lastCourseList);
            history.push(`/organizations/${id}/collections/${firstCourseId}`);  
        }
    };

    const handleViewSwitch = () =>  {
        LastView.write('student')
        history.push(`/profile`); 
    };

    const handleOpen= (event)=> {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`container-menu-switcher`}>
            <MenuItem
                onClick={handleOpen}
                size="medium"
                aria-controls={open ? 'menu-switcher' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className='switcher-menu-item'
            >
                <Avatar sx={{ width: 32, height: 32 }}>{userName ? userName[0] : 'O'}</Avatar>
                <p> {userName} </p>
            </MenuItem>
            <Menu   keepMounted
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{  
                        style: {  
                        width: 229,  
                        },  
                    }}
            >
                    {  coursesList && coursesList.length > 0 && 
                        <>         
                            { organizationsList && organizationsList.map((item, key) => (
                            <MenuItem key={key} 
                                fullWidth
                                onClick={() => handleOrganizationChange(item.id)}
                                className='switcher-menu-item'
                                >
                                <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}}>{item.title[0]}</Avatar>
                                { <p className={`organization-name`}> {organizationId === item.id ?
                                    <>
                                    {item.title}
                                    <CheckIcon sx={{ml: 2, color: '#e76d3e'}} />
                                    </>
                                    :
                                    item.title
                                    }
                                </p>
                                }
                            </MenuItem>
                                ))
                            }
                            <MenuItem key={organizationsList && organizationsList.length + 1} 
                                fullWidth
                                component={Link}
                                to={'/organizations/new'}
                                className='switcher-menu-item'
                                >
                                <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}}><AddCircleOutlineIcon/></Avatar>
                                New Community
                            </MenuItem>
                            <Divider/>
                                {context === "teacher" &&
                                    <MenuItem key={organizationsList && organizationsList.length} 
                                            fullWidth 
                                            onClick={handleViewSwitch} 
                                            className='switcher-menu-item'>
                                                <PersonIcon fontSize='medium' />
                                                PARTICIPANT VIEW
                                    </MenuItem>
                                }
                        </>
                    }
                    <MenuItem fullWidth 
                                        component={ Link }
                                        to='/settings'
                                        className='switcher-menu-item'>    
                                            <EditIcon fontSize='medium' />
                                            EDIT PROFILE
                                </MenuItem>
                <MenuItem fullWidth 
                        onClick={handleLogout} 
                        className='switcher-menu-item'>
                            <PowerSettingsNewIcon fontSize='medium' color='warning' />
                            LOGOUT
                </MenuItem>
            </Menu>
        </div>
    );
};

SwitcherMenu.propTypes = {
    userName: PropTypes.string,
    context: PropTypes.string,
    coursesList: PropTypes.array,
    handleLogout: PropTypes.func,
    organizationsList: PropTypes.array,
}

export default SwitcherMenu;