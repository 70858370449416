import { t } from 'i18n/index';

const helpLabels =  {
  already_issued: t('publicClaimPage:alreadyIssuedText'),
  not_eligible: t('publicClaimPage:notEligibleText'),
  pre_approved: t('publicClaimPage:preApprovedText'),
  unavailable: t('publicClaimPage:unavailable'),
};

export function getHelpText(logged_in, has_been_issued, is_enrolled, is_public, unavailable){
  if (unavailable) {
    return helpLabels.unavailable;
  } else if (logged_in && has_been_issued) {
    return helpLabels.already_issued;
  } else if (logged_in && !has_been_issued && !is_public && !is_enrolled) {
    return helpLabels.not_eligible;
  } else if (!logged_in && !is_public) {
    return helpLabels.pre_approved;
  }
}