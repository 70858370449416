import customToast from "./customToast";

export default function copyToClipboard(text, setOpen, setSeverity, setMessage, handleClose) {
    if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(text)
        .then(() => {
            customToast("success", "Link copied to clipboard!", setOpen, setSeverity, setMessage, handleClose);
        })
        .catch((error) => {
            customToast("warning", `Copy failed! ${error}`, setOpen, setSeverity, setMessage, handleClose);
        });
      }    
}