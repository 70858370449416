import { shortWallet } from "utils/shortWallet";

export const usersColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "name",
    name: "Full Name",
    selector: (row) => row.name,
    sortable: true,
    width: "30%",
  },
  {
    field: "email",
    name: "Email Address",
    selector: (row) => row.email,
    sortable: true,
    width: "20%",
  },
  {
    field: "wallet_address",
    name: "Wallet",
    selector: (row) => shortWallet(row.wallet_address),
    sortable: true,
    width: "20%",
  },
];
