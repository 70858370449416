export const rewardsColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "30%",
  },
  {
    field: "category",
    name: "Category",
    selector: (row) => row.category,
    sortable: true,
    width: "20%",
  },
];
