import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel, MenuItem, Select } from '@mui/material';

import { t } from 'i18n/index';

const PreferencesForm = ({data, onDataChange}) => {

    const {shirt_size} = data;

    const shirt_sizes = [
                          {value: "S", label: "S"},
                          {value: "M", label: "M"},
                          {value: "L", label: "L"},
                          {value: "XL", label: "XL"}
                        ];

    return (
        <div>
            <h3 className={`new-form-section`}>{t('merch:preferences_details')}</h3>
            <div className={ `new-form-field` }>

                <InputLabel id="demo-simple-select-label" color="warning">{t('merch:shirt_size')}</InputLabel>
                <Select
                    id="demo-simple-select"
                    value={ shirt_size || '' }
                    label={t('merch:shirt_size')}
                    name="shirt_size"
                    onChange={ onDataChange }
                    fullWidth
                    margin="dense"
                    color="warning"
                >
                    {shirt_sizes.map((item, key) => (
                        <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    );
};

PreferencesForm.propTypes = {
    data: PropTypes.object,
    onDataChange: PropTypes.func,
};

export default PreferencesForm;
