import { shortWallet } from 'utils/shortWallet';

export const participantsColumns = [
    { 
      field: 'id',
      name: 'ID',
      sortable: true,
      width: "10%",
      selector: (row) => row.id,
    },
    { 
      field: 'name',
      name: 'Name',
      sortable: true,
      width: "20%",
      selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
    },
    { 
      field: 'email',
      name: 'Email',
      sortable: true,
      width: "30%",
      selector: (row) => row.email,
    },
    { 
      field: 'wallet address',
      name: 'Wallet  Address',
      sortable: true,
      width: "30%",
      selector: (row) => shortWallet(row.wallet_address),
    },
    { 
      field: 'nfts count',
      name: 'NFTs',
      sortable: true,
      width: "10%",
      selector: (row) => row.rewards_count,
    },
  ];