import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material';

const StringButton = ({action, description}) => {
  return (
    <Button variant="string"
            color="secondary"
            onClick={action}
            sx={{ textTransform: 'none', fontSize: 16, textDecoration: 'underline' }}
    >
        {description}
    </Button>
  )
};

StringButton.propTypes = {
    action: PropTypes.func,
    description: PropTypes.string,
};

export default StringButton;