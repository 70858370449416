import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from '@mui/material';
import VideoPreview from 'components/VideoPreview';
import BackdropModal from 'components/BackdropModal';

import logo from 'assets/images/logo.png'


const Media = ({id, image, image_url, title, media_type, reward_type, video_url, showDetails, showDownloadButtons, organizationLogo}) => {

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const imageSrc = image ? window.URL.createObjectURL(image) : image_url;
    const imageClasses = [
        reward_type === 'achievement' ? 'certificate-image' : `badge-image`,
        !showDetails && 'blur'
    ].filter(Boolean).join(' ');

    const handleToggleBackdrop = () => {
      setOpenBackdrop(!openBackdrop);
    };

    const handleClose = () => {
      setOpenBackdrop(false);
    };

    if (!image && !image_url) {
        return <div className='skeletonBox' >
                    <Skeleton variant="rectangular" width={400} height={200} animation={media_type === 'video' ? 'wave' : false} />
                </div>
    }

    return (
            <div className="media-container">
                {  (media_type === 'image') ?
                    <img src={ imageSrc }
                        className={ imageClasses }
                        alt={ `${ title } Badge` }
                        onClick={(image_url && !image) && handleToggleBackdrop}
                            />
                :
                    <VideoPreview url={ video_url }
                                  poster={ image_url }
                                  showDetails={ showDetails }
                    />
                }

                {   showDetails ?
                    <BackdropModal
                        open={openBackdrop}
                        imageUrl={image_url}
                        title={title}
                        handleClose={handleClose}
                        handleToggle={handleToggleBackdrop}
                        showDownloadButtons={showDownloadButtons}
                        id={id}
                    />
                :
                    <div className={ 'claim-page-overlapping-container'}>
                        <img src={ organizationLogo ? organizationLogo : logo }
                            className={'claim-page-overlapping-image'}
                        />
                        <figcaption className={'claim-page-overlapping-cta'}>Claim Your Collectible</figcaption>
                    </div>
                }
            </div>
    );
};

Media.propTypes = {
    id: PropTypes.number,
    image_url: PropTypes.string,
    image: PropTypes.object,
    media_type: PropTypes.string,
    title: PropTypes.string,
    reward_type: PropTypes.string,
    video_url: PropTypes.string,
    showDetails: PropTypes.bool,
    showDownloadButtons: PropTypes.bool,
    organizationLogo: PropTypes.string,
};

export default Media;

