import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => (
  <div className="tabpanel"
    role="tabpanel"
    hidden={value !== index}
    {...other}
  >
    {value === index &&
        children
    }
  </div>
);
 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;