import React from 'react';
import { Link } from 'react-router-dom';

import { t } from 'i18n/index';

const Footer = () => {
  
  return (
    <div className={"student-profile-footer"} >
      <p> {t('studentProfile:collectorsPortal_Footer')}</p>
      <Link to='/organizations/new'>
        {t('studentProfile:collectorsPortal_FooterLink')}
      </Link>
    </div>

  )
};

export default Footer;