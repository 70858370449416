import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import {countBy} from 'lodash';

const defaultSettings = {
  datasets: [{
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

const Stats = ({ rewardInstances }) => {

  const [stat1, setStat1] = useState("");
  const [stat2, setStat2] = useState({...defaultSettings});
  const [stat3, setStat3] = useState("");
  const [chartKey, setChartKey] = useState(0);

  ChartJS.register(ArcElement, Tooltip);

  useEffect(() => {
    setStat1(rewardInstances.length);

    const stats = countBy(rewardInstances, (reward) => reward.category);
  
    const chart2 = {
      ...defaultSettings
    };

    chart2.labels = Object.keys(stats);
    chart2.datasets[0].data = Object.values(stats);

    setStat2(chart2);
    setChartKey(chartKey + 1);
    
    const claimedRewardInstances = rewardInstances.filter(({ claim_state }) => claim_state !== "unclaimed");
    const claimedCount = claimedRewardInstances.length;
    setStat3(claimedCount)
  }, [rewardInstances])  

  return (
    <div className="stats-grid grid custom-margin">
      <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1 }
                title='NFTs issued'
                />

      <StatCard type='chart'
                highlight={<Doughnut key={ chartKey } data={ stat2 } />}
                title='NFTs by type'
                />

      <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat3 }
                title='NFTs claimed'
                />
    </div>
)};

Stats.propTypes = {
  rewardInstances: PropTypes.object,
};

export default Stats;