import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StatusModal from 'components/StatusModal';
import PersonalDetailsForm from 'containers/student/Settings/PersonalDetailsForm';
import MerchDetailsForm from './MerchDetailsForm';

import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';

import { t } from 'i18n/index';

const UserSettings = () => {
        
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const handleError = (message) => {
        setError(message);
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const pageInfo = {
        name: t('settingsStudentProfilePage:pageInfo'),
      };

    return (
        <Layout context='student'
            pageInfo={ pageInfo }>

            <TabPanels labels={[t('settings_labels:personal_details'), t('settings_labels:merch_drops')]}>
                
                <PersonalDetailsForm onError={ handleError } />
                <MerchDetailsForm onError={ handleError } />

            </TabPanels>
            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </Layout>
    );
};

UserSettings.propTypes = {
    record: PropTypes.object,
}

export default UserSettings;
