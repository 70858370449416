import React, { Suspense, useState} from 'react';
import { NetworkErrorBoundary } from '@rest-hooks/react';
import Routes from 'routes';

import { WagmiConfig } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';

import { wagmiChains, wagmiClient } from 'utils/wagmiClient';
import { ToastContext } from 'utils/context/ToastContext';
import { PermissionsContext } from 'utils/context/PermissionsContext';
import logo from 'assets/images/logo.png';
import CustomSnackbar from 'components/CustomSnackbar';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Syne", sans-serif'
  }
});

function App() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("warning");
  const [message, setMessage] = useState("Crowdclass");
  const [permissions, setPermissions] = useState([]);

  return (
    <Suspense fallback={ <img src={ logo } className="pulse center-absolutely"/> }>
      <NetworkErrorBoundary>
          <ThemeProvider theme={theme}>
            <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider modalSize="compact" chains={wagmiChains}>

            <ToastContext.Provider value={{ open: open, setOpen: setOpen, message: message, setMessage: setMessage, severity: severity, setSeverity: setSeverity }}>
                <CustomSnackbar />
                <PermissionsContext.Provider value={{ permissions: permissions, setPermissions: setPermissions }}>
                  <Routes />
                </PermissionsContext.Provider>
            </ToastContext.Provider>

            </RainbowKitProvider>
            </WagmiConfig>
          </ThemeProvider>
      </NetworkErrorBoundary>
    </Suspense>
  );
}

export default App;
