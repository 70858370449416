import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import logo from 'assets/images/logo-crowdclass.svg'
import SwipeableTemporaryDrawer from './swipeableDrawer';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const NavBar = ({ handleLogout, activeMenuItem }) => {

  return (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed" color='inherit'>
      <Toolbar className='navbar'>
        
        <Link to="/profile">
          <img src={logo} className='navbar-image' alt="Crowdclass"/>
        </Link>

        <SwipeableTemporaryDrawer handleLogout={handleLogout} activeMenuItem={activeMenuItem}></SwipeableTemporaryDrawer>
      
      </Toolbar>
    </AppBar>
  </Box>
)};

NavBar.propTypes = {
    handleLogout: PropTypes.func,
    activeMenuItem: PropTypes.number,
};

export default NavBar;