import React from 'react'
import PropTypes from 'prop-types'

import DesktopStepper from './DesktopStepper'
import MobileStepper from './MobileStepper'

const index = ({step, gift}) => {

  const activeStep = gift ? step - 1 : step - 2;

  const steps = [ "Billing Details", "Complete Payment" ];

  if (gift) {
    steps.unshift("Recipient Details");
    steps.push("Gift Sent")
  } else {
    steps.push("Payment Confirmation");
  }

  return (
    <div>
      <DesktopStepper activeStep={ activeStep } steps={ steps } /> 
      <MobileStepper activeStep={ activeStep } steps={ steps } />
    </div>
  );
};

index.propTypes = {
    step: PropTypes.number,
    gift: PropTypes.bool,
};

export default index;