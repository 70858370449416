import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react'

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import { studentsColumns } from 'utils/columns/studentsColumns';


const StudentSelectionGrid = ({students, onChange}) => {
  const [query, setQuery] = useState("");
  const [filteredStudent, setFilteredStudent] = useState([]);

  useEffect(() => {
    const result = students.filter(({first_name, last_name, email}) => {
    last_name = last_name || ''

    const lcQuery = query.toLowerCase();

    return (first_name.toLowerCase().includes(lcQuery) ||
           last_name.toLowerCase().includes(lcQuery) ||
           email.toLowerCase().includes(lcQuery));
    });

    setFilteredStudent(result);
  }, [query])


  return (
        <DataSelectionTable records={filteredStudent}
                            columns={studentsColumns}
                            checkboxSelectRows
                            onSelectChange={onChange}
                            onQueryChange={setQuery}
                            queryString={query}
        />
  )
};

StudentSelectionGrid.propTypes = {
  students: PropTypes.array,
  selectedIds: PropTypes.array,
  onChange: PropTypes.func
};

export default StudentSelectionGrid;