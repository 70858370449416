import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import { t } from 'i18n/index';

const DeliveryDetailsForm = ({data, onDataChange}) => {

    const {address, city, post_code} = data;

    return (
        <div>
            <h3 className={`new-form-section`}>{t('merch:delivery_details')}</h3>
                <div className={ `new-form-field` }>
                    <TextField label={t('invoiceForm:streetAddress') + " *"} 
                                name="address"
                                value={ address || '' }
                                onChange={ onDataChange }
                                fullWidth 
                                margin="dense" 
                                color="warning">
                    </TextField>
                </div>
                <div className='invoice-form-row-container'>
                    <TextField label={t('invoiceForm:postCode') + " *"} 
                                name="post_code"
                                value={ post_code || '' }
                                onChange={ onDataChange }
                                fullWidth 
                                margin="dense" 
                                color="warning">
                    </TextField>
                    <TextField label={t('invoiceForm:city') + " *"} 
                                name="city"
                                value={ city || '' }
                                onChange={ onDataChange }
                                fullWidth 
                                margin="dense" 
                                color="warning">
                    </TextField>
                </div>
        </div>
    )
}

DeliveryDetailsForm.propTypes = {
    data: PropTypes.object,
    onDataChange: PropTypes.func,
};

export default DeliveryDetailsForm;