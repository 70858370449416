import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

const TextPanel = ({ offer,
                     onOfferChange,
                     isDisabled,
                    }) => {

  return (
    <div>
      <TextField 
        label={ "Text *" }
        value={ offer && offer.content_text }
        onChange={ event => onOfferChange({
                      ...offer,
                      content_text: event.target.value
                    })
                }
        disabled={ isDisabled }
        multiline={true}
        minRows={ 3 }
        fullWidth
        color="warning"
        helperText="This text will be displayed when the offer is redeemed."
      />
      <div className={'new-form-field'} >
        <TextField 
          label={ "Link (optional)" }
          value={ offer && offer.content_text_link }
          onChange={ event => onOfferChange({
                        ...offer,
                        content_text_link: event.target.value
                      })
                  }
          disabled={ isDisabled }
          fullWidth
          color="warning"
          helperText="Add a link if you would like to display a 'Continue' button below the unlocked text. The link should be the url where you would like the user to be redirected to on click."
        />
      </div>
    </div>  
  );
};

TextPanel.propTypes = {
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default TextPanel;
