import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { labels } from './translate';

i18n
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        ns: [
            'rewardType',
            'claimState',
            'studentProfile',
            'activeMenu',
            'actionsMenu',
            'connectModalAddress',
            'connectLaterModal',
            'shareRewardModal',
            'tooltip',
            'dropMenuOptions',
            'loginPage',
            'signUpPage',
            'passwordReset',
            'connectWallet',
            'tcLabel',
            'metadata',
            'publicReward',
            'publicClaimPage',
            'checkoutForm',
            'giftModal',
            'giftForm',
            'invoiceForm',
            'paymentProviderSelector',
            'settingsStudentProfilePage',
            'singUpForm',
            'authModal',
            'offersLabels',
            'userOrders',
            'redeemModal',
            'pageInfo',
            'publishModal',
            'paymentProviderSelectionModal',
            'globalVariables'
        ],           
        resources: labels,
    });

    const t  = i18n.t

export { i18n, t } 