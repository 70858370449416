import formattedDate from "utils/formattedDate";

import { t } from "i18n/index";

const redeemedLabel = (redeemed, redeem_limit) => {
  return `${redeemed}${redeem_limit ? ` / ${redeem_limit}` : ''}  ${t("offersLabels:redeemed")}`;
};

export const finalizedOffersColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "10%",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "30%",
    editable: true,
  },
  {
    field: "redeemed",
    name: "Redeemed",
    selector: (row) => redeemedLabel(row.redeemed, row.redeem_limit),
    sortable: true,
    width: "15%",
    editable: true,
  },
  {
    field: "endDate",
    name: "End Date",
    selector: (row) => formattedDate(row.end_date),
    sortable: true,
    width: "15%",
    editable: true,
  },
  {
    field: "createdAt",
    name: "Created at",
    selector: (row) => formattedDate(row.created_at),
    sortable: true,
    width: "15%",
    editable: true,
  },
];
