import React from 'react';
import Link from '@mui/material/Link';
import { termsOfUseLink, privacyPolicyLink } from 'utils/urls';
import { t } from 'i18n/index';


export const TCLabel = <p className='label-in-checkbox'>
                        {t('tcLabel:firstPhrase')}&nbsp;
                        <Link href={termsOfUseLink} target="_blank">{t('tcLabel:secondPhrase')}</Link>
                        &nbsp;{t('tcLabel:thirdPhrase')}&nbsp;
                        <Link href={privacyPolicyLink} target="_blank">{t('tcLabel:fourthPhrase')}</Link>
                       </p>;
