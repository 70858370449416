import React from 'react'
import PropTypes from 'prop-types'

import TextPanel from './TextPanel'
import RevealPanel from './RevealPanel';
import Accordion from '../Accordion';

import { offerType, contentType, emptyRevealOffer, emtpyTextOffer } from 'utils/offersFormHelper';

const ContentSettings = ({ offer, 
                           onOfferChange,
                           isDisabled,
                           mysteryBoxCollections,
                        }) => {

  const renderTextPanel = () => {
    return <TextPanel offer={ offer }
                      onOfferChange={ onOfferChange }
                      isDisabled={ isDisabled } />
  };

  const renderRevealPanel = () => {
    return <RevealPanel offer={ offer }
                      onOfferChange={ onOfferChange }
                      isDisabled={ isDisabled || offer.offer_type === "ExternalOffer" }
                      mysteryBoxCollections={ mysteryBoxCollections }/>
  };

  const handlePanelChange = (panel) => {
    const previousContentType = offer.offer_type;

    if (previousContentType === panel) {
        return
    } else {
        onOfferChange({
            ...offer,
            content_type: panel,
            ...emtpyTextOffer,
            ...emptyRevealOffer,
          });
    }
  };
                            
  return (
    <div>
      <h3 className={`new-form-section`}>Content</h3>
      <Accordion expanded={offer.content_type === contentType.text}
                 onChange={() => handlePanelChange(contentType.text)}
                 disabled={isDisabled}
                 title="Unlockable Content"
                 details={renderTextPanel()}
                 />
      <Accordion expanded={offer.content_type === contentType.reveal}
                 onChange={() => handlePanelChange(contentType.reveal)}
                 disabled={isDisabled || offer.offer_type === offerType.external}
                 title="Reveal"
                 details={renderRevealPanel()}
                 />
    </div>
  );
};

ContentSettings.propTypes = {
    offer: PropTypes.object,
    onOfferChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    mysteryBoxCollections: PropTypes.array,
};

export default ContentSettings;