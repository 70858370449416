import React, { useState, useCallback } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams, useHistory } from "react-router-dom";
import { mapKeys } from 'lodash';
import * as XLSX from 'xlsx/xlsx.mjs';

import StudentResource from 'resources/organization/StudentResource';
import ImportResource from 'resources/organization/ImportResource';

import Button from '@mui/material/Button';
import UploadFileIcon from "@mui/icons-material/UploadFile";

import Layout from 'components/layouts/index';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';

import StatusModal from 'components/StatusModal';
import CsvModal from './CsvModal';

const pageInfo = {
  name: "Import participant list",
}
const emptyFile = { file: null, filename: '' };

const ImportStudent = () => {
  const [file, setFile] = useState({...emptyFile });
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [errorTable, setErrorTable] = useState([]);
  const [fullNameTable, setFullNameTable] = useState(true)
  
  const { fetch } = useController();
  const history = useHistory();

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const { setOpen, setMessage, setSeverity } = useToast();

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseModals = () => {
    setFile({...emptyFile });
    setError(null);
    setOpenErrorModal(false);
    setOpenCSVModal(false);
    setRows([]);
    setErrorTable([]);
    setFullNameTable(true);
  };

  const handleValidation = (file) => {
    if (!file){
      setError("Please upload a file.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleOnSubmit = useCallback(
    async (file) => {
      handleValidation(file)
      try {
        const formData = new FormData();
        formData.append('file', file.file);

        const { counter, errors } = await fetch(ImportResource.create(), {organization_id: organizationId, course_id: courseId}, formData);
        // success!

        if ( counter && errors.length === 0 ) {
          fetch(StudentResource.list(), {organization_id: organizationId, course_id: courseId});
          customToast('success', `${counter} participants added.`, setOpen, setSeverity, setMessage);
          history.goBack();

        } else if (errors[0][0].includes("encoding")){
          setError(errors[0][0]);
          handleOpenErrorModal();

        } else {
          setErrorTable(true);
          fetch(StudentResource.list(), {organization_id: organizationId, course_id: courseId});

          if (counter) {
            errors.unshift(`${counter} ${counter === 1 ? 'participant' : 'participants'} added.`);
          }

          setError( errors );
          handleOpenErrorModal();        
        }
      }  catch (error) {
          setError(error.message);
          handleOpenErrorModal();
      }
    },
    [fetch],
  );

  const handleFileUpload = async (event) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const newFile = {
      file: file,
      filename: file.name,
    };
    setFile(newFile);

    const json = XLSX.utils
        .sheet_to_json(workbook.Sheets['Sheet1'])	        
        .map(row => mapKeys(row, (value, key) => key.trim()))
    setRows(json);
    setFullNameTable(!Object.prototype.hasOwnProperty.call(json[0], "first_name"))
    setOpenCSVModal(true);
    event.target.value = ''
  };

  return (
    <Layout context='teacher'
            back={ true }
            activeMenuItem={ 1 }
            pageInfo={ pageInfo } >

      <form id="admin-rewards-add" onSubmit={ (e) => handleOnSubmit(e, file['file']) } >
        <div className="new-reward-wrapper">

          <div className={ `new-form-field` }>
            <p>You can upload any .csv or .xlsx, with your participants  list. <br />
            (Download 
              <a href={process.env.PUBLIC_URL + "/import_template_crowdclass.csv"}
                 download={"import_template_crowdclass.csv"}
                 style={{color: '#F75201'}}>
                  &nbsp;template file here
              </a>)
            </p>
          </div>
              <Button
                component="label"
                variant="outlined"
                color="warning"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload spreadsheet
                <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden onChange={event => handleFileUpload(event)} />
              </Button>
            {file.filename}
          <div className={ `container-preview` }>
            <CsvModal open={ openCSVModal }
                      onClose={ handleCloseModals }
                      onConfirm={() => handleOnSubmit(file) }
                      rows={rows}
                      fullNameTable = {fullNameTable}
            />
          </div>
        </div>
      </form>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseModals } table= {errorTable}/>}

    </Layout>
  )
};

export default ImportStudent;