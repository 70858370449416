import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import RewardResource from 'resources/organization/RewardResource';

import GeneralSettings from './formComponents/generalSettings';
import Image from './formComponents/image';
import LinkSettings from './formComponents/linkSettings';
import EligibilitySettings from './formComponents/eligibilitySettings';
import ContentSettings from './formComponents/contentSettings';
import Footer from './formComponents/Footer';

import OfferPreview from 'components/OfferPreview/index';

import { defaultOffer, sortingOptions } from 'utils/offersFormHelper';
import { courseType, rewardType } from 'utils/constants';

const OffersForm = ({record={}, onSubmit, onDelete, isDisabled}) => {

    const [offer, setOffer] = useState( {...defaultOffer, ...record} );
    const [organizationNfts, setOrganizationNfts] = useState([]); //static
    const [filteredOrganizationNfts, setFilteredOrganizationNfts] = useState([]);
    const [organizationCollections, setOrganizationCollections] = useState([]); //static
    const [mysteryBoxCollections, setMysteryBoxCollections] = useState([]);
    const [image, setImage] = useState(null);
    const [nftInputValue, setNftInputValue] = useState("");
    const [collectionInputValue, setCollectionInputValue] = useState("");

    const params  = useParams();
    const {fetch} = useController();
    const organizationId = parseInt(params.organizationId);

    useEffect(() => {

        const fetchRewardsAndHandleData = async () => {

            const rewards = await fetch(RewardResource.list(), {organization_id: organizationId});

            const organizationNfts = rewards.sort(sortingOptions)
                                            .filter(item => item.category !== rewardType.MYSTERYBOX.value)
                                            .map(reward => ({ label: `${reward.title}`, id: reward.id, course_id: reward.course_id }));
            setOrganizationNfts(organizationNfts);
            setFilteredOrganizationNfts(organizationNfts.filter(
                                                item => item.course_id === offer.course_id
                                       ))

            const uniqueCourses = rewards.reduce((courses, reward) => {
                const existingCourse = courses.find((course) => course.id === reward.course_id);
                if (!existingCourse) {
                    courses.push({
                    label: `${reward.course_title}`,
                    id: reward.course_id,
                    category: reward.course_category,
                    supply_limit: reward.mystery_box_supply,
                    published: reward.course_published,
                    });
                }
                return courses;
            }, []);
            setOrganizationCollections(uniqueCourses);

            const mysteryBoxCollections = uniqueCourses.filter(element => element.category === courseType.MYSTERYBOX.value
                                                               && element.id !== offer.course_id
                                                               && element.published)
            setMysteryBoxCollections(mysteryBoxCollections);
        };

        fetchRewardsAndHandleData();

    }, [])

    return (
        <div className={`form-offers-container`}>
            <form className={`form-offers-wrapper`} onSubmit={ (e) => onSubmit(e, offer, offer['file'], offer['reveal_file']) }>
                
                <GeneralSettings 
                        offer={ offer } 
                        onOfferChange={ setOffer } 
                        isDisabled={ isDisabled } 
                />

                { !isDisabled &&
                    <Image 
                        offer={ offer } 
                        onOfferChange={ setOffer }
                        onImageChange={ setImage }
                    />
                }

                <LinkSettings 
                        offer={ offer } 
                        onOfferChange={ setOffer } 
                        isDisabled={ isDisabled } 
                />

                <EligibilitySettings 
                        offer={ offer }
                        onOfferChange={ setOffer }
                        isDisabled={ isDisabled }
                        organizationNfts={ organizationNfts }
                        filteredOrganizationNfts={ filteredOrganizationNfts }
                        onFilteredOrganizationNftsChange={ setFilteredOrganizationNfts }
                        organizationCollections={ organizationCollections }           
                        onMysteryBoxCollectionsChange={ setMysteryBoxCollections }
                        nftInputValue={ nftInputValue }
                        onNftInputValueChange={ setNftInputValue}
                        collectionInputValue={ collectionInputValue }
                        onCollectionInputValueChange={ setCollectionInputValue } 
                        />

                <ContentSettings  
                        offer={ offer }
                        onOfferChange={ setOffer }
                        isDisabled={ isDisabled }
                        mysteryBoxCollections={ mysteryBoxCollections }
                        />

                <Footer offer={ offer }
                        onDelete={ () => onDelete(offer.id) }
                />

            </form>
            <OfferPreview  
                        item={ offer }
                        image={ image }
                        preview={true}
            />
        </div>
    );
};

OffersForm.propTypes = {
    record: PropTypes.object,
    onSubmit: PropTypes.func,
    course: PropTypes.object,
    onDelete: PropTypes.func,
    isDisabled: PropTypes.bool,
    onContentChange: PropTypes.func,
};

export default OffersForm;
