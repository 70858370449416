import React from 'react'
import PropTypes from 'prop-types'

import InternalPanel from './InternalPanel';
import ExternalPanel from './ExternalPanel';
import Accordion from '../Accordion';

import { offerType, emptyRevealOffer } from 'utils/offersFormHelper';

const EligibilitySettings = ({ 
                              offer, 
                              onOfferChange,
                              isDisabled,
                              organizationNfts,
                              filteredOrganizationNfts,
                              onFilteredOrganizationNftsChange,
                              organizationCollections,
                              onMysteryBoxCollectionsChange,
                              nftInputValue, 
                              onNftInputValueChange,
                              collectionInputValue,
                              onCollectionInputValueChange,
                            }) => {
                        
  const disable = (panel) => {
    return isDisabled || (offer.id && offer.offer_type !== panel)
  };

  const renderExternalPanel = () => {
    return <ExternalPanel offer={offer}
                          onOfferChange={onOfferChange}
                          isDisabled={isDisabled} />
  };

  const renderInternalPanel = () => {
    return <InternalPanel offer={offer}
      onOfferChange={onOfferChange}
      isDisabled={isDisabled}
      organizationNfts={organizationNfts}
      filteredOrganizationNfts={filteredOrganizationNfts}
      onFilteredOrganizationNftsChange={onFilteredOrganizationNftsChange}
      organizationCollections={organizationCollections}
      onMysteryBoxCollectionsChange={onMysteryBoxCollectionsChange}
      nftInputValue={nftInputValue}
      onNftInputValueChange={onNftInputValueChange}
      collectionInputValue={collectionInputValue}
      onCollectionInputValueChange={onCollectionInputValueChange} />
  };

  const handlePanelChange = (panel) => {
    const previousType = offer.offer_type;

    if (previousType === panel){
        return
    } else {
      onOfferChange({
        ...offer,
        offer_type: panel,
        reward_id: null,
        reward_title: null,
        course_id: null,
        course_title: null,
        ...emptyRevealOffer,
      });
    }
  };

  return (
    <div>
      <h3 className={`new-form-section`}>Elegibility Criteria</h3>
      <Accordion expanded={offer.offer_type === offerType.internal}
                 onChange={() => handlePanelChange(offerType.internal)}
                 disabled={disable(offerType.internal)}
                 title="Crowdclass Collection / NFT"
                 details={renderInternalPanel()}
                 />
      <Accordion expanded={offer.offer_type === offerType.external}
                 onChange={() => handlePanelChange(offerType.external)}
                 disabled={disable(offerType.external)}
                 title="External Smart Contract NFT"
                 details={renderExternalPanel()}
                 />
    </div>
  );
};

EligibilitySettings.propTypes = {
    offer: PropTypes.object,
    onOfferChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    organizationNfts: PropTypes.array,
    filteredOrganizationNfts: PropTypes.array,
    onFilteredOrganizationNftsChange: PropTypes.func,
    organizationCollections: PropTypes.array,
    onMysteryBoxCollectionsChange: PropTypes.func,
    nftInputValue: PropTypes.string,
    onNftInputValueChange: PropTypes.func,
    collectionInputValue: PropTypes.string,
    onCollectionInputValueChange: PropTypes.func,
};

export default EligibilitySettings;