import React from  'react';

import TableButton from 'components/TableButton';
import formattedDate from 'utils/formattedDate';
import { courseType } from 'utils/constants';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

export const collectionsColumns = [
  { 
    field: 'id',
    name: 'ID',
    sortable: true,
    width: "10%",
    selector: (row) => row.id,
  },
  { 
    field: 'title',
    name: 'Title',
    sortable: true,
    width: "20%",
    selector: (row) => row.title,
  },
  { 
    field: 'category',
    name: 'Category',
    sortable: true,
    width: "25%",
    selector: (row) => Object.values(courseType).find((type) => type.value === row.category)?.label,
  },
  { 
    field: 'nfts count',
    name: 'NFTs count',
    sortable: true,
    width: "10%",
    selector: (row) => row.nfts_count,
  },
  { 
    field: 'claims',
    name: 'Claims',
    sortable: true,
    width: "10%",
    selector: (row) => row.claims,
  },
  { 
    field: 'creation date',
    name: 'Creation Date',
    sortable: true,
    width: "15%",
    selector: (row) => formattedDate(row.created_at),
  },
  { field: 'inspect', 
    selector : (row) => 
      <TableButton title={'Inspect'} Icon={ZoomInIcon} link={`/organizations/${row.organization_id}/collections/${row.id}`} />,
    sortable: false,
    width: '10%' 
  },
];
