import formattedDate from 'utils/formattedDate';
import { convertPriceFromCents , currencySymbol } from 'utils/rewards';

const paymentTypes = {
  'stripe': 'Credit Card',
  'utrust': 'Crypto',
  'eupago': 'MBWay',
}

export const ordersColumns = [
    {
      field: 'id',
      name: 'Order ID',
      sortable: true,
      width: "15%",
      selector: (row) => row.id,
    },
    {
      field: 'user_id',
      name: 'User ID',
      sortable: true,
      width: "15%",
      selector: (row) => row.user_id,
    },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      width: "20%",
      selector: (row) => row.status
    },
    {
      field: 'total_price',
      name: 'Total Price',
      sortable: true,
      width: "15%",
      selector: (row) => `${convertPriceFromCents(row.total_price)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'payment_provider',
      name: 'Payment Type',
      width: "20%",
      selector: (row) => paymentTypes[row.payment_provider]
    },
    {
      field: 'completed_at',
      name: 'Completed At',
      sortable: true,
      width: "20%",
      selector: (row) => formattedDate(row.charge_succeded_at)
    },
  ];
