import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class RedeemResource extends AuthenticatedResource {
  static urlRoot = '/redeem'

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}