import React from 'react';
import PropTypes from 'prop-types';

import { courseType } from 'utils/constants';

import Link from '@mui/material/Link';

const Organization = ({org_desktop, org_mobile, organization, activity_title, activity_link, activity_type}) => {

    const displayCourse = activity_title && activity_type !== courseType.MYSTERYBOX.value;

    return (
        <div className='collection-container'>
            { org_mobile &&
                <div className={`mobile`} >
                  <>
                    { organization &&
                      <div className="organization-mobile">by&nbsp;
                          { organization }
                      </div>
                    }
                    { displayCourse &&
                        <div className="course-mobile"> @
                          <Link href={activity_link ? activity_link : '/' } target="_blank" rel="noopener" sx={{color: 'black'}}>
                            { activity_title }
                          </Link>
                        </div>
                    }
                  </>
                </div>
            }
            { org_desktop &&
                <div className={`desktop`} >
                    <>
                        { organization &&
                            <div className="organization-desktop">by&nbsp;
                                { organization }
                            </div>
                        }

                        { displayCourse &&
                            <div className="course-desktop"> @
                            <Link href={activity_link ? activity_link : '/' } target="_blank" rel="noopener">
                                { activity_title }
                            </Link>
                            </div>
                        }
                    </>
                </div>
            }
        </div>
    );
};

Organization.propTypes = {
    org_desktop: PropTypes.bool,
    org_mobile: PropTypes.bool,
    organization: PropTypes.string,
    activity_title: PropTypes.string,
    activity_link: PropTypes.string,
    activity_type: PropTypes.string,
};

export default Organization;
