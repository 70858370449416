import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

import StatCard from 'components/StatCard'
import { currencySymbol } from 'utils/rewards';

const defaultSettings = {
  datasets: [{
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

const Stats = ({ stat1, stat2, stat3, currency }) => {  
  ChartJS.register(ArcElement, Tooltip);

  const chart2 = {
    ...defaultSettings
  }

  chart2.labels = Object.keys(stat2);
  chart2.datasets[0].data = Object.values(stat2);

  return (
    <div className="stats-grid grid custom-margin">
      <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1.counter }
                title='Completed Orders'
                />

      <StatCard type='number'
                color='rgb(255, 205, 86)'
                highlight={ stat2.counter }
                title='Sold NFTs'
                />

      <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat3.counter }
                title={`Total Revenue (${currencySymbol[currency]})`}
                />
      
    </div>
)};

Stats.propTypes = {
  stat1: PropTypes.object,
  stat2: PropTypes.object,
  stat3: PropTypes.object,
  currency: PropTypes.string
};

export default Stats;